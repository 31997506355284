
.cd-pricing-switcher {
    margin: 0;
    color: $white;
    font-size: 12px;
    padding: 10px 0 5px 0;
    border-bottom: 1px solid var(--account-setting-switch-border-color);

    @include mobile-tablet {
        padding-bottom: 10px;
    }
}

.cd-pricing-switcher .fieldset {
    display: inline-block;
    position: relative;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid $gray-input-border;
    margin: 0;
}

.cd-pricing-switcher p.on {
    background-image: var(--account-setting-pricing-switcher-background-image-on);
    background-color: var(--account-setting-pricing-switcher-background-color-on);
}

.cd-pricing-switcher p.off {
    background: var(--account-setting-pricing-switcher-background-off);
}

.cd-pricing-switcher input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.cd-pricing-switcher input[type="radio"]:checked+label {
    color: $white;
    cursor: default;
}

.cd-pricing-switcher label {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 27px;
    height: 15px;
    line-height: 20px;
    cursor: pointer;
    transition: color 0.25s ease;
    text-align: center;
    white-space: nowrap;
    margin: 2px 0;
}

.cd-pricing-switcher .cd-switch {
    position: absolute;
    top: 2px;
    left: 1px;
    height: 20px;
    width: 24px;
    background-color: $white;
    border-radius: 5px;
    transition: transform 0.25s ease;

    @include mobile-tablet {
        left: 16px;
    }
}

.cd-pricing-switcher input[type="radio"]:checked+label+.cd-switch,
.cd-pricing-switcher input[type="radio"]:checked+label:nth-of-type(n)+.cd-switch {
    transform: translateX(-30px);
}

.cd-pricing-switcher .toggle-text {
    font-size:14px;
    vertical-align: top;
    margin: 0 0 0 16px;
    display: inline-block;
}

.cd-pricing-switcher span.on {
    color: var(--account-setting-switch-text-active-color);
}

.cd-pricing-switcher span.off {
    color: var(--account-setting-switch-text-inactive-color);
}
