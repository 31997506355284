.reset-password-confirm {
  padding-top: $header-height;
  min-height: 100%;

  .footer {
    padding-top: 71px;
    padding-bottom: 75px;
  }

  .button--primary {
    margin-top: 6px;
    padding-right: 28px;
    padding-left: 28px;
    height: 40px;
    font-size: 14px;
    font-family: $base-font-family--semibold;
  }

  .round-box {
    margin: auto;
    padding-top: 43px;
    padding-right: 12px;
    padding-bottom: 43px;
    padding-left: 12px;
    min-width: $reset-password-confirm-form-min-width;
    max-width: $reset-password-confirm-form-width;
    text-align: center;
  }

  &__confirm-text {
    padding-top: 46px;
    font-size: 22px;
  }

  &--blue-bg {
    @include gradient(background, $biscay, $reset-password-confirm-background-degrees, $blue-gradient-set);
  }

  &__success-img {
    display: block;
    margin: auto;
    width: 400px;
  }
}

@include desktop {
  .reset-password-confirm {
    .round-box {
      width: $reset-password-confirm-form-width;
    }
  }
}
