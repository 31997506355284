ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

dl {
  margin: 0;
}

dt {
  margin: 0;
  font-weight: 600;
}

dd {
  margin: 0;
}
