.button__icon {
  @each $label, $content, $desktop, $tablet, $mobile in $fontastic-icons {
    @include icon-button($label, $content, $desktop, $tablet, $mobile);
  }
}


// Display icon only
.button--only-icon {
  width: $button-width-icon-only;
  border-width: 0;
  font-size: 0;

  &::before {
    margin: 0;
  }

  &:hover {
    padding: 0;
    width: $button-width;
    border-width: 1px;
    font-size: $button-font-size;

    &::before {
      margin-right: 10px;
    }
  }
  @include tablet-only {
    width: $button-width;
    border-width: 1px;
    font-size: $button-font-size;
  }

  @include mobile-only {
    width: 100%;
  }
}

/* Icons */
.icon-label {
  color: var(--sidebar-navigation-link-not-active-icon-color);
  display: inline-block;

  @include mobile-only {
    text-align: center;
  }
  // add colors to icons, labels
  // as well as different states, specified by class name
  // ex: 'active' state
  @include icon-label-pseudo-state-colors();

  .icon-label__icon {
    font-size: $icon-font-size-mobile;
    font-family: $font-family-icon-label-icons;

    @include mobile-only {
      height: $icon-height-mobile;
    }

    @include tablet {
      font-size: $icon-font-size-tablet;
    }

    @include desktop {
      font-size: $icon-font-size-desktop;
    }
  }

  // edge case: hamburger only
  &.hamburger {
    .icon-label__icon {
      font-size: $icon-font-size-hamburger;
    }
  }

  .icon-label__label {
    font-size: $label-font-size-mobile;
    font-family: $font-family-icon-label-labels;

    @include mobile-only {
      font-family: $font-family-icon-label-labels;
    }

    @include desktop {
      padding: $label-padding-desktop;
      font-size: $label-font-size-desktop;
    }
  }

  &__icon,
  &__label {
    display: inline-block;
    vertical-align: middle;

    @include mobile-only {
      padding: 0;
    }

    @include tablet-only {
      display: block;
      padding: 0;
    }
  }

  @each $label, $content, $desktop, $tablet, $mobile in $fontastic-icons {
    @include icon-label($label, $content, $desktop, $tablet, $mobile);
  }
}
