.login-content {
  position: relative;
  min-width: $mobile-min-width;
  width: 100%;
  height: 100%;

  @include gradient(background, $blue-dark-menu, $reset-password-background-degrees, $blue-gradient-set);

  .greeting-label {
    padding: 81px 0 80px;

    .login__practice-address,
    .login__additional-info {
      display: none;
    }
  }

  .login__form {
    margin: auto;
    padding: 0;
    width: 90%;

    .login__form-title {
      display: none;
    }

    .form__input {
      padding: 8px 0;
      border: 0;
      border-bottom: 1px solid $white;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      color: $white;

      &:focus {
        border-bottom: 1px solid $white;
        box-shadow: none;
      }

      &:nth-of-type(1) {
        margin-bottom: 23px;
      }
    }

    .button--primary {
      margin-top: 24px;
      width: 100%;
      height: 45px;
      text-transform: uppercase;
    }

    .login__bottom-labels {
      margin: 20px 0 0;

      .login__sign-up-label,
      .login__forgot-password-label {
        color: $gray-close;
        text-align: center;
        font-size: 14px;
      }

      .login__sign-up-link,
      .login__forgot-password-link {
        color: $gray-close;
        text-decoration: underline;
        font-family: $base-font-family--semibold;

        &:hover {
          color: $footer-links-hover;
        }
      }

      .login__sign-up-label {
        margin: 0 0 10px;
      }
    }
  }
}

@include mobile-only {
  .login-content {
    .login__form {
      background: transparent;
    }
  }
}

@include tablet {
  .login-content {
    .greeting-label {
      margin: auto;
      width: 321px;
      font-size: 42px;

      .login__practice-address {
        display: block;
        margin-top: 10px;
        font-size: 16px;
        font-family: $base-font-family;
        line-height: 1.7;
      }
    }

    .login__form {
      margin: auto;
      padding: 45px 40px;
      width: 495px;
      box-shadow: 0 0 0 8px $blue-bayoux;

      .login__form-title {
        display: block;
        margin-bottom: 38px;
        text-align: center;
        font-size: 26px;
      }

      .form__input {
        border-bottom: 1px solid $gray-login-border;
        color: $black-login-input;

        &:focus {
          border-bottom: 2px solid $navy;
        }
      }

      .form__input,
      .button--primary {
        margin-bottom: 25px;
      }

      .login__bottom-labels {
        .login__sign-up-label,
        .login__forgot-password-label {
          display: inline-block;
          color: $shuttle-gray;
          font-size: 16px;
        }

        .login__sign-up-label {
          width: 57%;
          text-align: left;
        }

        .login__forgot-password-label {
          width: 42%;
          text-align: right;
        }

        .login__sign-up-link,
        .login__forgot-password-link {
          color: $navy;
          font-size: 16px;

          &:hover {
            color: $navy;
          }
        }
      }
    }
  }
}

@include desktop {
  .login-content {
    padding: 110px 0 0;
    text-align: center;

    .greeting-label,
    .login__form {
      display: inline-block;
    }

    .greeting-label {
      margin-right: 55px;
      padding: 125px 0 0;
      width: 430px;
      height: 435px;
      vertical-align: top;
      text-align: left;
      font-size: 38px;
      font-family: $base-font-family--semibold;

      .login__practice-address {
        font-family: $base-font-family--semibold;
      }

      .login__additional-info {
        position: relative;
        display: block;
        font-size: 16px;
        font-family: $base-font-family;

        &-list {
          margin-top: 20px;
          list-style: disc inside;
          line-height: 1.5;
        }
      }
    }
  }
}
