@import '../variables/color';
@import './default';
// WARNING
// Do Not Import any other file over here
//This file is used by GULP to convert SASS variables to css variables
// EDGE & IE is depend on this
:root {
  @each $key, $value in $default-theme {
    --#{$key}: #{$value};
  }
}

[theme="pet-portal-theme"] {
  @each $key, $value in $pet-portal-theme {
    --#{$key}: #{$value};
  }
}

[theme="sesame-portal-theme"] {
  @each $key, $value in $sesame-portal-theme {
    --#{$key}: #{$value};
  }
}

