@mixin mobile-only {
  @media (max-width: #{$tablet-min-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min-width}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-min-width}) and (max-width: #{$desktop-min-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-min-width}) {
    @content;
  }
}

@mixin xy-align($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin gradient($type, $fallback, $degree, $colors...) {
  @if $type == background {
    background-color: $fallback;
    background: linear-gradient($degree, $colors) no-repeat;
  }

  @if $type == text {
    color: $fallback;
    -webkit-font-smoothing: antialiased;
    background: -webkit-linear-gradient($degree, $colors) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

// gradients
@mixin gradient-button($background, $shadow) {
  background-image: $background;

  @if $shadow != null {
    box-shadow: $shadow;
  }
}

// gradients
@mixin get-background-color($background, $shadow) {

  // type = color if: #fff or rgb()
  // type = string if: linear-gradient ...
  @if type-of($background) == 'color' {
    background-color: $background;
  }

  @else {
    background-image: $background;
  }

  @if $shadow != null {
    box-shadow: $shadow;
  }
}
