// Normal
$proximanova: "proximanova";

@include font-face($proximanova, "./fonts/proximanova/proximanova-regular-webfont", normal, $file-formats: eot svg woff ttf);

// Thin
$proximanova-thin: "proximanova-thin";

@include font-face($proximanova-thin, "./fonts/proximanova/proximanova-thin-webfont", 100, $file-formats: ttf);

// Light
$proximanova-light: "proximanova-light";

@include font-face($proximanova-light, "./fonts/proximanova/proximanova-light-webfont", lighter, $file-formats: eot woff ttf);

// Semi Bold
$proximanova-semi-bold: "proximanova-semi-bold";

@include font-face($proximanova-semi-bold, "./fonts/proximanova/proximanova-semi-bold-webfont", 500, $file-formats: eot svg woff ttf);

// Bold
$proximanova-bold: "proximanova-bold";

@include font-face($proximanova-bold, "./fonts/proximanova/proximanova-bold", bold, $file-formats: eot svg woff ttf);

// Extra Bold
$proximanova-extra-bold: "proximanova-extra-bold";

@include font-face($proximanova-extra-bold, "./fonts/proximanova/proximanova-extra-bold-webfont", bolder, $file-formats: eot svg woff ttf);