@mixin switch-label {
  position: absolute;
  display: block;
  margin: 0;
  color: $white;
  font-family: $font-family-semi-bold;
  line-height: $switch-label-line-height;
  cursor: pointer;
  align-self: center;
  user-select: none;

  transition: all 0.5s ease-in-out;
}

.switch {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;

  .switch__toggle {
    position: absolute;
    visibility: hidden;
    margin-left: -9999px;
  }

  .switch__toggle:checked {
    & ~ .switch__body {
      background-image: $switch-checked-gradient;

      &::before {
        margin-left: 32px;
      }

      .switch__state-label--on {
        visibility: visible;
        font-size: $switch-label-font-size;
      }

      .switch__state-label--off {
        visibility: hidden;
        font-size: $switch-label-font-size--small;
      }
    }

    & ~ .switch__label {
      color: $base-font-color;
    }
  }

  .switch__body {
    position: relative;
    display: flex;
    margin: 0;
    padding: 2px;
    width: 60px;
    height: 24px;
    outline: none;
    border-radius: 5px;
    background-color: $gray-switch-off;
    cursor: pointer;
    user-select: none;

    transition: background 0.5s ease-in-out;

    // the switch
    &::before {
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      display: block;
      width: 24px;
      border-radius: 5px;
      background-color: $white;
      content: "";

      transition: margin 0.5s ease-in-out;
    }

    .switch__state-label--on {
      left: 7px;
      visibility: hidden;
      font-size: $switch-label-font-size--small;

      @include switch-label;
    }

    .switch__state-label--off {
      right: 7px;
      font-size: $switch-label-font-size;

      @include switch-label;
    }
  }

  .switch__label {
    display: flex;
    margin: 0;
    margin-right: 10px;
    font-size: $switch-label-font-size--big;
    font-family: $base-font-family;
  }
}
