#{$all-buttons} {
  display: inline-block;
  padding: $small-spacing $base-spacing;
  border: 0;
  border-radius: $base-border-radius;
  background-color: $azure-dull;
  color: $white;
  vertical-align: middle;
  text-align: center;
  height: 34px;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 600;
  font-size: $base-font-size;
  font-family: $base-font-family;
  line-height: 1;
  cursor: pointer;
  transition: background-color $base-duration $base-timing;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  user-select: none;

  &:hover,
  &:focus {
    background-color: var(--welcome-modal-button-hover-bg-color);
    color: $white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: $azure-dull;
    }
  }
}

// General rule for anchors
a:hover {
  cursor: pointer;
}
