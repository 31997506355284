.sub-header {
  margin-top: $header-height;
  width: $sub-header-width;
  height: $sub-header-mobile-height;
  background-image: url("../images/pp-Hero.jpg");
  background-position: center;
  background-size: 100%;

  @media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3/2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5) {
    background-image: url("../images/pp-Hero@2x.jpg");
  }

  .sub-header__container {
    height: 100%;
    color: $white;
    text-align: center;

    .sub-header__slogan {
      display: none;
      padding-top: 30px;
      width: 100%;
      text-align: center;
      font-size: $slogan-font-size;
      font-family: $font-family-bold;
    }

    .sub-header__content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .sub-header__email--decoration {
        text-decoration: underline;
      }

      .sub-header__phone {
        font-size: $phone-mobile-font-size;
      }

      .sub-header__phone::before {
        margin-right: 7px;
        content: 'Call Us';
        font-size: $phone-mobile-font-size;
        font-family: $font-family-bold;
      }

      .sub-header__address {
        display: flex;
        flex-direction: column;
        font-size: $address-mobile-font-size;
      }

      .sub-header__email {
        font-size: $email-mobile-font-size;
      }
    }
  }
}

@include mobile-only {
  .sub-header {
    .sub-header__container {
      .sub-header__content {
        padding-top: 20px;

        .sub-header__phone {
          font-family: $font-family-bold;
        }

        .sub-header__address {
          padding-top: 7px;
        }

        .sub-header__email {
          padding-top: 7px;
        }
      }
    }
  }
}

@include tablet-only {
  .sub-header {
    margin-top: $header-height;
    margin-left: $menu-tablet-width;
    width: calc(#{$sub-header-width} - #{$menu-tablet-width});
    height: $sub-header-tablet-height;

    .sub-header__container {
      .sub-header__slogan {
        display: block;
        padding-top: 30px;
      }

      .sub-header__content {
        flex-direction: row;
        padding-top: 5px;
        flex-wrap: wrap;

        .sub-header__phone {
          font-size: $phone-tablet-font-size;
          order: 1;
        }

        .sub-header__phone::before {
          content: '';
        }

        .sub-header__phone::after {
          padding-right: 5px;
          padding-left: 5px;
          content: ' | ';
        }

        .sub-header__email {
          margin-top: 10px;
          width: 18%;
          font-size: $email-tablet-font-size;
          order: 2;
        }

        .sub-header__email::after {
          content: ' | ';
          text-decoration: none;
        }

        .sub-header__address {
          display: block;
          width: 100%;
          font-size: $address-tablet-font-size;
          order: 3;
        }
      }
    }
  }
}

@include desktop {
  .sub-header {
    margin-top: $header-height;
    margin-left: $menu-desktop-width-expanded;
    width: calc(#{$sub-header-width} - #{$menu-desktop-width-expanded});
    height: $sub-header-desktop-height;

    transition: all 0.5s ease-in-out;

    .sub-header__container {
      .sub-header__slogan {
        display: block;
        padding-top: 60px;
      }

      .sub-header__content {
        flex-direction: row;

        .sub-header__phone {
          margin-top: -5px;
          font-size: $phone-desktop-font-size;
          order: 1;
        }

        .sub-header__phone::before {
          content: '';
        }

        .sub-header__phone::after {
          padding-right: 10px;
          content: ' | ';
        }

        .sub-header__email {
          font-size: $email-desktop-font-size;
          order: 2;
        }

        .sub-header__email::after {
          padding-right: 10px;
          content: ' | ';
        }

        .sub-header__address {
          flex-direction: row;
          font-size: $address-desktop-font-size;
          order: 3;

          .sub-header__address--padding {
            padding-left: 5px;
          }
        }
      }
    }
  }
}
