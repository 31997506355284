@import '../../variables/color';


.pets {
    display: flex;
    font-family: "proximanova";

    &-empty {
        color: $aluminium;
        font-size: 16px;
    }
    &-label {
        color: $mid-gray !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        font-family: "proximanova-bold";
        margin-left: 0px !important;
    }
    &-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 0px;
        &__name {
            color: $mid-gray !important;
            font-size: 16px !important; 
        }
    }
    &.active{
        margin-bottom: 20px;
        .pets-list {
            &__desc {
                display: none;
                color: $cornflower-blue !important;
                font-size: 14px !important;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    &.inactive {
        .pets-list {
            min-width: 700px;
            &__name {
                min-width: 165px;
            }
            justify-content: flex-start;
            &__desc {
                color: $aluminium !important;
                font-size: 16px !important;
            }
        }
    }

}
