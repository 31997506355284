// base component variables

// z-index list, cascading values relative to base
$z-index-base: 100;
$z-index-header: $z-index-base + 1;
$z-index-tooltips: $z-index-base + 1;
$z-index-menu: $z-index-base + 2;
$z-index-more-menu-modal: $z-index-base + 3;
$z-index-notification-modal-overlay: $z-index-base + 1;
$z-index-notification-modal: $z-index-base + 2;
$z-index-notification-alert: $z-index-base + 1;
$z-index-notification-alert-text: $z-index-base - 1;

// Page Elements

// Header
$header-height: 50px;
$header-width: 100%;

// Menu
$menu-mobile-height: 50px;
$menu-tablet-height: 100%;
$menu-desktop-height: 100%;
$menu-tablet-width: 84px;
$menu-desktop-width-collapsed: 65px;
$menu-desktop-width-expanded: 220px;

// Typography
$font-family-icon-label-icons: $patient-portal;
$font-family-semi-bold: $proximanova-semi-bold;
$font-family-bold: $proximanova-bold;
$font-family-icon-label-labels: $font-family-semi-bold;

// Breakpoints
$mobile-min-width: 320px;
$tablet-min-width: 768px;
$desktop-min-width: 992px;
$desktop-max-width: 1200px;

// user notification component sizes 
$user-notification-alert-font-size: 12px;

// appointments
$appointments-calender-item-width: 60px;
$appointments-calender-item-height: 70px;
$appointments-calendar-container-mobile-width: 26%;
$appointments-calendar-container-tablet-width: 79px;

//Round box
$round-box-radius: 5px;

// button sizes
$button-height: 34px;
$button-height-small: 24px;
$button-width: 100%;
$button-width-icon-only: 45px;
$button-font-size: 14px;
$button-font-size-small: 12px;
