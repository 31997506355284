//
// Spinner
// ==============================

@-webkit-keyframes pulse {
  0% { opacity: 0.0}
  10% { opacity: .20}
  20% { opacity: .40 }
  30% { opacity: .60 }
  40% { opacity: .80 } 
  50% { opacity: 1.0}
  60% { opacity: .80}
  70% { opacity: .60}
  80% { opacity: .40}
  90% { opacity: .20}
  100% { opacity: 0.0}
}



// Base
// ------------------------------

.Spinner {
  display: inline-block;
  font-size: $spinner-dot-size;
  height: $spinner-dot-size;
  position: relative;
  text-align: center;
  vertical-align: middle;
}





// Dots
// ------------------------------

.Spinner_dot {
  animation: pulse 1s infinite ease-in-out;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;

  &.Spinner_dot--second {
    animation-delay: 160ms;
    margin-left: 1em;
  }

  &.Spinner_dot--third {
    animation-delay: 320ms;
    margin-left: 1em;
  }
}



// Types
// ------------------------------


// default
.Spinner--default > .Spinner_dot { background-color: $spinner-dot-bg; }

// primary
.Spinner--primary > .Spinner_dot { background-color: $dodger-blue-shade-3; }

// inverted
.Spinner--inverted > .Spinner_dot { background-color: $white; }




// Sizes
// ------------------------------


// small
.Spinner--sm {
  font-size: $spinner-dot-size-sm;
  height: $spinner-dot-size-sm;
}

// large
.Spinner--lg {
  font-size: $spinner-dot-size-lg;
  height: $spinner-dot-size-lg;
}





// Inside Buttons
// ------------------------------

.Button > .Spinner {
  .Spinner--sm {
    font-size: $spinner-dot-size-sm;
    height: $spinner-dot-size-sm;
  }
  margin-right: 2em;

  &:only-child {
    margin-right: 0;
  }
}

.centerContainer {
  text-align: center;
  width: 50%;
  height: 50%;
  overflow: inherit;
  margin: auto;
  position: absolute;
  top: 0; 
  left: 0;
  bottom: 0;
  right: 0;
}
