fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

legend {
  margin-bottom: $small-spacing / 2;
  padding: 0;
  font-weight: 600;
}

label {
  display: block;
  margin-bottom: $small-spacing / 2;
  font-weight: normal; // 600;
}

input,
select,
textarea {
  display: block;
  font-size: $base-font-size;
  font-family: $base-font-family;
}

#{$all-text-inputs} {
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: $base-spacing / 3;
  width: 100%;
  border: $base-border;
  border-radius: $base-border-radius;
  background-color: $white;
  box-shadow: $form-box-shadow;
  transition: border-color $base-duration $base-timing;
  appearance: none;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    outline: none;
    border-color: $azure-dull;
    box-shadow: $form-box-shadow-focus;
  }

  &:disabled {
    background-color: shade($white, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}

/**
 * Chrome exhibits strange behavior when custom fonts are used
 * on select inputs. This fixes the garbled text.
 */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select {
    font-family: sans-serif;
  }
}
