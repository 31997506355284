.progress-indicator {
  /* parent relatively positioned */
  position: relative;
  left: 130px;
  display: inline-block;
  /* set label default state */

  @include mobile-only {
    left: 0;
    width: 100%;
    height: 59px;

    label {
      font-size: 14px;
    }

    label:nth-of-type(1) {
      bottom: -10px;
      left: 0;
    }

    label:nth-of-type(2) {
      bottom: -10px;
      left: 39%;
    }

    label:nth-of-type(3) {
      right: -51px;
      bottom: -10px;
      left: auto;
    }

    div:nth-of-type(1) {
      left: 32px;
    }

    div:nth-of-type(2) {
      left: 47%;
      z-index: 2;
    }

    div:nth-of-type(3) {
      right: 18px;
    }

    span {
      left: 34px;
      width: 45px;
      width: 4px;
    }

    span:nth-of-type(1) {
      top: 15px;
      left: 66px;
      width: 37%;
      height: 4px;
    }

    span:nth-of-type(2) {
      top: 15px;
      left: 52%;
      width: 40%;
      height: 4px;
    }
  }

  @include tablet {
    width: 142px;
    height: 192px;

    label {
      top: 0;
      left: 50px;
    }

    label:nth-of-type(1) {
      top: 5px;
      left: -122px;
    }

    label:nth-of-type(2) {
      top: 85px;
      left: -113px;
    }

    label:nth-of-type(3) {
      top: 165px;
      left: -152px;
    }

    div:nth-of-type(2) {
      top: 79px;
    }

    div:nth-of-type(3) {
      top: 158px;
    }

    span {
      left: 14px;
      width: 4px;
      height: 45px;
    }

    span:nth-of-type(1) {
      top: 34px;
    }

    span:nth-of-type(2) {
      top: 113px;
    }
  }

  @include desktop {
    width: 142px;
    height: 192px;

    label {
      top: 0;
      left: 50px;
    }

    label:nth-of-type(1) {
      top: 5px;
      left: -122px;
    }

    label:nth-of-type(2) {
      top: 85px;
      left: -113px;
    }

    label:nth-of-type(3) {
      top: 165px;
      left: -152px;
    }

    div:nth-of-type(2) {
      top: 79px;
    }

    div:nth-of-type(3) {
      top: 158px;
    }

    span {
      left: 14px;
      width: 4px;
      height: 45px;
    }

    span:nth-of-type(1) {
      top: 34px;
    }

    span:nth-of-type(2) {
      top: 113px;
    }
  }

  /* all children absolutely positioned */
  label,
  div,
  span {
    position: absolute;
  }

  label,
  div,
  div i,
  span,
  span i {
    display: inline-block;
  }

  /* active states, bold and white */
  div.progress-indicator--active,
  span.progress-indicator--active {
    background-color: $white;
  }

  label.progress-indicator--active {
    color: $white;
  }

  div i.progress-indicator--active {
    font-family: 'patient-portal';
  }

  div i.progress-indicator--active::after {
    content: 'o';
  }
  /* set color defaults */
  label {
    color: $gray-default;
    font-weight: 600;
    font-size: 16px;
    font-family: $base-font-family;
  }

  div,
  span {
    background-color: $gray-default;
  }

  /* set checkbox/number default state */
  div {
    width: 34px;
    height: 34px;
    border-radius: 16px;
    text-align: center;
    line-height: 34px;
  }

  div i {
    margin-bottom: 1px;
    height: auto;
    color: $navy;
    vertical-align: auto;
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    line-height: auto;
  }

  div i.progress-indicator--completed {
    font-family: $patient-portal;
  }

  div.progress-indicator--completed,
  span.progress-indicator--completed {
    background-color: $green-progress-completed;
  }

  label.progress-indicator--completed {
    color: $green-progress-completed;
  }

  div i.progress-indicator--completed::after {
    content: 'x';
  }
}
