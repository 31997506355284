.content__main {
  .account-settings {
    background-color: $white;
    &__line--wider {
      position: absolute;
      right: 0;
      left: 0;
    }

    &__line {
      width: 100%;
      height: 1px;
      background-color: $gray-line
      
    }

    &__heading {
      margin-left: -27px;
      padding: 0 30px;
      height: 59px;
      color: $blue-dark-menu;
      font-size: 18px;
      line-height: 65px;
    }

    &__tabs {
      display: flex;
      height: 50px;
      font-size: 16px;
      line-height: 50px;
      justify-content: center;

      .tabs__general-info {
        margin-right: 30px;
        margin-top: -1px;
        z-index: 1;
      }

      .active {
        border-width: 2px;
        border-bottom: 2px solid $blue-indicator;

        .icon-label__icon {
          color: $blue-indicator;
        }
      }

      .inactive span {
        color: $rock-blue;
      }

      .icon-label .icon-label__icon {
        display: inline-block;
        height: 50px;
        font-size: 18px;
      }
    }

    &__container {
      margin-top: $header-height;
      width: 100%;
      font-family: $base-font-family;
    }

    &__tabs-container {
      .title {
        color: $waikawa-gray;
        font-size: 18px;
      }

      .tabs--flex {
        position: relative;
        display: flex;
        flex-direction: column;
      }

      .tabs-content-left,
      .tabs-content-right {
        width: 100%;
      }

      .tabs-content-left {
        padding-top: 30px;
      }

      .tabs-content-right {
        padding: 30px 0;
      }

      .general-info-buttons-container--editable {
        position: static;
        margin-top: 12px;

        .pencil-visible {
          display: none;
        }
      }

      .general-info-buttons-container {
        position: absolute;
        top: 25px;
        right: 0;

        .general-info-save {
          display: none;
        }

        .general-info-edit {
          border: none;
          background-color: transparent;
          background-image: none;
          box-shadow: none;
          color: $blue-indicator;
        }

        .general-info-edit:focus {
          outline: none;
        }

        .pencil-visible {
          position: absolute;
          top: 7px;
          left: -10px;
          display: block;

          .icon-label__icon {
            color: $blue-indicator;
            font-size: 14px;
          }

          .icon-label:visited {
            color: $blue-indicator;
          }
        }
      }

      .general-info__content {
        .formatted-input {
          padding: 0;
          border: none;
          box-shadow: none;
        }

        .format-inline {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;

          div {
            margin-right: 10px;
          }
        }

        .general-info-form input {
          background-color: transparent;
        }

        .general-info-form input:hover {
          border: 0;
          cursor: default;
        }

        .input-hover-enable input:hover {
          border: 1px solid $gray-input-border;
          cursor: text;
        }

        .button--primary,
        .button--secondary {
          line-height: 3px;
        }

        .button--primary,
        .button--secondary:hover {
          cursor: pointer;
        }

        .form {
          padding-bottom: 10px;
          height: auto;

          .form__input {
            height: auto;
            background-color: $whitesmoke;
          }

          &__login-email:hover {
            cursor: not-allowed;
          }

          .disabled-input {
            border-color: $gray-input-border;
            background-color: $gray-input-disabled-background;
            color: $gray-input;
            cursor: not-allowed;
          }
        }

        .form-padding {
          padding-right: 10px;
        }

        .form--smaller {
          color: $waterloo;
          font-size: 14px;
          font-family: $base-font-family--semibold;
        }

        .form,
        .form--smaller,
        .form--smallest {
          width: 100%;
        }

        .general-info-form input {
          color: $black-gray-text;
        }

        .address {
          .address-form {
            display: flex;
            flex-direction: column;

            .address-form__country {
              order: 4;
            }

            .address-form__address1 {
              order: 1;
            }

            .address-form__address2 {
              order: 2;
            }

            .address-form__address3 {
              order: 3;
            }

            .general-info-buttons-container--editable {
              order: 5;
            }

            .address-label--hidden {
              display: none;
            }

            .address-label--visible {
              display: block;
            }

            .format-inline-concat {
              display: flex;
              flex-direction: row;
            }
          }
        }

        .account-permissions {
          display: none;
        }

        .password {
          .pwd-requirements {
            margin-top: 10px;
            color: $gray-password-required;
            font-size: 14px;
            line-height: 12px;
          }
        }
      }

      .notifications__content {
        display: none;

        .notification-options {
          line-height: 45px;
        }

        .switch {
          flex-direction: row;
          justify-content: space-between;

          &__label {
            margin-left: 3px;
            color: $blue-dark-menu;
          }
        }
      }
    }
  }
}

@include tablet {
  .content__main {
    .account-settings__tabs {
      .tabs__manage-notifications__title::before {
        content: 'Manage ';
      }

      .tabs__manage-notifications__title::after {
        content: 's';
      }
    }

    .account-settings__container {
      .account-settings {
        &__heading {
          margin-left: -27px;
        }
      }
    }

    .account-settings__tabs-container {
      .general-info-buttons-container--editable {
        position: static;
      }

      .notifications__content {
        .switch {
          flex-direction: row-reverse;
          justify-content: flex-end;

          &__label {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@include desktop {
  .content__main {
    .account-settings__container {
      .account-settings {
        &__heading {
          margin-left: 0;
          line-height: 58px
        }
      }
    }

    .account-settings__tabs-container {
      .tabs-content-left {
        padding: 30px;
        width: 40%;
      }

      .tabs-content-right {
        width: 60%;
      }

      .tabs--flex {
        flex-direction: row;
      }

      .general-info-buttons-container--editable {
        .pencil-visible {
          display: none;
        }
      }

      .general-info-buttons-container {
        position: static;

        .general-info-save {
          display: none;
        }

        .general-info-edit {
          background-color: $navy;
          background-image: $button-primary-color-hover;
          color: $white;
        }

        .icon-label {
          display: none;
        }

        .pencil-visible {
          display: none;
        }
      }

      .general-info__content {
        .format-inline {
          flex-direction: row;
        }

        .form {
          width: 350px;
        }

        .form-smaller {
          width: 165px;
        }

        .form-smallest {
          width: 75px;
        }

        .address {
          .address-form {
            display: block;

            label {
              display: block;
            }

            .address-label--hidden {
              display: block;
            }
          }
        }

        .account-permissions {
          display: flex;

          .account-permission-holder {
            padding-right: 20px;
            padding-left: 20px;
            font-size: 14px;
            font-family: $base-font-family--bold;
          }

          .account-permission-status {
            color: $green-granted;
            font-size: 14px;
          }
        }

        .password {
          .password-form input {
            width: 500px;
          }

          .form--error::after {
            right: 0;
            left: 470px;
          }
        }
      }

      .notifications__content {
        .form {
          .switch {
            flex-direction: row-reverse;
            justify-content: flex-end;

            &__label {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
