.reset-password {
  padding-top: $header-height;
  min-height: 100%;

  .footer {
    padding-top: 71px;
    padding-bottom: 75px;
  }

  .button--primary {
    width: 100%;
    font-size: 14px;
    font-family: $base-font-family--semibold;
  }

  .form {
    width: 100%;
  }

  &__form {
    display: block;
    margin: auto;
    padding: 50px;
    max-width: $reset-password-form-width;
    width: 100%;
    height: auto;

    .button--primary {
      margin-top: 25px;
    }
  }

  &__email {
    display: block;
    font-size: 17px;
    font-family: $base-font-family--bold;
  }

  &__title {
    margin-bottom: 34px;
    text-align: center;
    font-size: 17px;
    font-family: $base-font-family;
  }

  &__pass-description {
    margin-bottom: 14px;
    font-size: 14px;
  }

  &--blue-bg {
    @include gradient(background, $blue-dark-menu, $reset-password-background-degrees, $blue-gradient-set);
  }
}

.form--error + .reset-password__pass-description {
  margin-top: 0;
}

@include desktop {
  .reset-password {
    &__form {
      width: $reset-password-form-width;
    }
  }
}
