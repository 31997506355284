@-webkit-keyframes switchButtonfadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}  
@keyframes switchButtonfadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@-webkit-keyframes switchButtonfadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}  
@keyframes switchButtonfadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}

.button--select--wrapper{
  position: relative;
  .button--select {
      cursor: pointer;
      display: inline-flex;
      margin: 0;
      padding: 12px;
      width: 170px;
      height: 45px;
      color: $amparo-blue;
      white-space: nowrap;
      font-size: 15px;
      font-family: $font-family-semi-bold;

      position: relative;
      border: 8px solid transparent;
      border-radius: 14px;
      background: $white;
      background-clip: padding-box;
      padding: 4px;
      padding-right: 25px;
      &::after {
        
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: $radical-background-after;
        content: '';
        z-index: -1;
        border-radius: 8px;
      }
      .switch-icons {
        position: relative;
        margin-left: 13px;
        font-size: 13px;
        .top {
          position: absolute;
          top: 0px;
        }
        .down {
          position: absolute;
          top: 6px;
        }
      }

  }
  .switch-dropdown{
    cursor: initial;
    white-space: nowrap;
    position: absolute;
    top: 41px;
    left: 5px;
    min-width: 155px;
    z-index: 100;
    display: inline-block;
    box-sizing: border-box;
    box-shadow: $button-alternate-box-shadow;
    border-radius: 4px;
    background-color: $white;
    opacity: 0;
    -webkit-animation: switchButtonfadeOut 1s;
    animation: switchButtonfadeOut 1s;
    &.open{
      opacity: 1;
      -webkit-animation: switchButtonfadeIn 1s;
      animation: switchButtonfadeIn 1s;
    }
    ul {
      padding: 16px;
      li {
        cursor: pointer;
        svg {
          color: transparent;
        }
      }
      li.active {
        svg {
          color: $success-color;
          font-size: 11px;
          margin-right: 5px;
        }
      }
    }
  }
}
