.dashboardHeader {
  height: 242px;
  background: url(../images/pp-Hero@2x.jpg);

  &.petHeader {
    background-image: url(../images/pp-Pet@2x.png);
  }

  background-position: center;
  background-size: cover;
  text-align: center;

}

.mobileBusinessInfo {
  display: none;
}

.businessInfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $white;
  margin: auto;
  padding-top: 64px !important;
  line-height: initial;
  font-family: $proximanova;

  &__main-title {
    font-family: $proximanova;
    word-wrap: break-word;
    font-size: 32px;
    line-height: 1.25;
    font-weight: bold;
  }

  .mobileBusinessInfo {
    display: none;
  }
}

@include tablet {
  .mobileBusinessInfo {
    display: none;
  }
}

@include mobile-only {
  .businessInfo {
    display: none;
  }
  .mobileBusinessInfo {
    display: block;
    color: $white;
    margin: auto;
    padding-top: 80px !important;
    line-height: initial;
  }
  .mainPhone {
    font-family: ProximaNova;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.92;
    color: $white;
  }
  .businessAddress {
    font-size: 13px;
    width: 200px;
    margin: auto;
  }
  .businessEmail {
    margin: auto;
    text-decoration: underline;
    padding-top: 10px;
    font-size: 12px;
  }
}

.dashboardContainer {
  position: relative;
  background-color: var(--platform-bg-color);

  @include mobile-only {
    margin-bottom: 30px;
  }
}

.dashboardRight {
  position: static;
  padding: 0px 16px 1px 16px;
  top: 0;
  right: 0;
  width: 100%;
  background-color: var(--platform-bg-color);

  @include mobile {
    padding-bottom: 50px;
  }

  @include desktop {
    position: absolute;
    padding: 30px 30px 0px 0px;
  }
}

.dashboardRightContainer {
  width: 100%;
  float: none;

  @include desktop {
    width: 343px;
    float: right;
  }
}

.dashboardLeft {
  margin-right: 0;
  padding: 30px 16px 1px 16px;

  @include desktop {
    margin-right: 373px;
    padding: 30px 30px 1px 30px;
  }
}

.dashboardFull {
  padding: 30px 30px 1px 30px;
}

.dashboardDiv {
  z-index: 10;
  position: relative;
  margin-bottom: 30px;
}

.dashboardBorder {
  border-radius: 4px;
  background-color: $white;
  box-shadow: $button-primary-shadow;
  min-height: 100px;
}

@include mobile-only {
  .businessInfo {
    width: auto;
  }
}

.dependentModal {
  border-radius: 8px;
  // box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  max-width: 800px;
  min-width: 520px;
  text-align: center;
  padding: 15px;
  font-family: $proximanova;

  &-listContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: 0 auto;
  }

  @include mobile {
    min-width: auto;
    max-width: 315px;
  }

  &-dependentList {
    // min-width: 200px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 12px;
  }

  &-closeModal {
    float: right
  }

  &-highlightText {
    color: #2A2B33;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 15px;
  }

  &-highlightDes {
    color: #646470;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.27px;
    // line-height: 22px;
  }

  &-container {
    display: inherit;
    // width: 200px;
    // margin: auto;
    // float: left;
    margin-bottom: 15px;
  }

  &-dependentName {
    color: #63687D;
    font-weight: 700;
    font-size: 15px;
    display: inline;
  }

  &-condition {
    color: #646470;
    letter-spacing: 0.27px;
  }

  &-createAcc {
    background-image: linear-gradient(159.56deg, #0089FF 0%, #2E34E4 100%);
    margin: 0px 0px 20px 0px;
    width: 250px;
  }
}

.accessBlockedModal {
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.5);
  width: 579px;
  height: 304px;
  text-align: center;
  border-radius: 8px;
  padding: 0px 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mobile {
    max-width: 315px;
    min-width: auto;
    // font-size: 12px;
    // padding: 20px;
  }

  &-description {
    color: #646470;
    letter-spacing: 0.27px;
  }

  &-title {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  &-close {
    background-image: linear-gradient(159.56deg, #0089FF 0%, #2E34E4 100%);
    min-width: 250px;
    margin-top: 20px;
    border-radius: 5px;
    height: 34px;
  }
}

/////////////////////////////////////////////////////////////////
.check-box-component {
  display: flex;
  /* Default style for checkbox */
  .checkbox {
    margin: 0 10px 0 24px;
    opacity: 0;
  }

  /* Create a custom checkbox with default style*/
  .checkmark {
    color: white;
    background-image: linear-gradient(0deg, #2D34E5 0%, #0089FF 100%);
    border: 1px solid #0089FF;
    border-radius: 3px;
    box-shadow: inset 0 2px 3px 0 rgba(194, 194, 205, 0.5);
    width: 22px;
    height: 22px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .notChecked {
    background-image: linear-gradient(white, white);
    border: 1px solid #BCBFCC;
  }
}

////////////////////////////////////////////////////////////////

.welcomeModal {
  padding: 30px 45px 38px;
  width: 579px;
  text-align: center;
}

.welcomeText {
  width: 302px;
  height: 31px;
  font-family: ProximaNova;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  color: $amparo-blue;
}

.startBtn {
  width: 250px;
  height: 34px;
  border-radius: 5px;
  background-image: var(--welcome-modal-button-active-image-color);
  background-color: var(--welcome-modal-button-active-bg-color);
  box-shadow: 0 2px 1px 0 $gray-ultra-transparent;

  &:hover {
    background-color: var(--welcome-modal-button-active-bg-color);
  }
}

.welcomeDes {
  padding-bottom: 30px;
  font-family: ProximaNova;
  font-size: 15px;
  width: auto;
  margin: auto;
  line-height: 1.27;
  text-align: center;
  color: $oslo-gray;
}

.PasswordSubmitModal {
  width: 490.9px;
  height: 359px;
  border-radius: 5px;
  background-color: $white;
  box-shadow: $modal-box-shadow-direction $modal-box-shadow-tint;
  padding: 20px;

  * {
    padding-top: 10px;
  }

  div {
    text-align: center;

    img {
      height: 151px;
    }

    .PasswordDes {
      font-family: ProximaNova;
      font-size: 22px;
      line-height: 1.09;
      color: $black-gray-text;
      padding-top: 20px;
    }

    .DoneBtn {
      width: 91px;
      height: 40.5px;
      border-radius: 5px;
      background-image: $switch-checked-gradient;
    }
  }
}

@include mobile {
  .dashboardHeader {
    height: 300px;
  }

  .welcomeModal {
    max-width: 315px;
    min-width: auto;
    font-size: 12px;
    padding: 20px;
  }
  .PasswordSubmitModal {
    max-width: 315px;
    min-width: auto;

    div {
      .PasswordDes {
        height: 52px;
      }

      img {
        max-height: 110px;
      }

      .DoneBtn {
        width: 239px;
        height: 43.5px;
        border-radius: 5px;
        background-image: $switch-checked-gradient;
      }
    }
  }
}

.business-wrapper {
  display: flex;
  justify-content: center;
}

.business-flex {
  display: flex;
  align-items: center;
}

.businessHeader {
  margin-top: 20px;
}

.business-divider {
  margin: 0 10px;

}

.business-margin-left-10 {
  margin-left: 10px;
}

.business-margin-bottom-0 {
  margin-bottom: 0px;
}

.business-margin-bottom-10 {
  margin-bottom: 10px;
}

.business-margin-right-10 {
  margin-right: 10px;
}

.business-margin-right-15 {
  margin-top: 15px;
}

.business-contact {
  padding: 20px;
}

.business-phone {
  color: $white;
  border: 1px solid $white;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 0 20px;
}

.business-phone, .business-phone:visited, .business-phone:hover, .business-phone:active {
  color: inherit;
}

.business-email {
  text-decoration: underline;
}

@media only screen and (max-width: 1200px) {
  .businessInfo {
    font-size: 13px;
  }
}

@media only screen and (max-width: 880px) {
  .businessInfo {
    font-size: 10px;
  }
}
@media only screen and (max-width: 820px) {
  .businessInfo {
    font-size: 10px;
  }
}
