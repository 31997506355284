.btn--link{
  color: $blue-indicator;
}
.settings-header {
  display: flex;
  align-items: center;
  background-color: var(--setting-header-background-color);
  height: 65px;
  font-size: 18px;

  .settings-header-text {
    margin: 0 20px 0 30px;
    color: var(--account-setting-header-text-color);

    @include mobile-tablet {
      margin-left:16px;
    }
  }

  @include mobile-tablet {
    height: 54px;
    margin-top: 60px;
  }
}

.settings-menu {
  display: flex;
  justify-content: center;
  height: 50px;
  border-top: 1px solid $gray-line;
  border-bottom: 1px solid $gray-line;
  padding-top: 13px;
  color: $blue-indicator;

  span {
    color: var(--setting-header-inactive-color);
  }

  span:hover {
    cursor: pointer;
  }

  .highlighted-menu-option {
    color: var(--setting-header-active-color);
    border-bottom: solid 1px var(--setting-header-active-color);

    span {
      color: var(--setting-header-active-color);
    }
  }

  & > span:nth-child(1) {
    &:before {
      content: "a";
      font-family: patient-portal;
      margin-right: 10px;
    }
  }

  & > span:nth-child(2) {
    margin-left: 40px;

    &:before {
      content: "b";
      font-family: patient-portal;
      margin-right: 10px;
    }

    span.notification.mobile {
      display: none;
    }
  }

  @include mobile {
    span:nth-child(2) {
      span.notification.desktop {
        display: none;
      }

      span.notification.mobile {
        display: inline-block;
      }
    }
  }
}

.setting-container {
  padding: 20px 0;      

  span {
    font-size: 18px;
    color: var(--setting-container-span-color);
    margin: 0 20px 0 30px;
  }

  span.edit.mobile {
    display: none;
  }

  .setting-text {
    font-size: 14px;
    font-weight: bold;
    color: var(--setting-content-title-text-color);
  }

  .font-weight-normal {
    font-weight: normal;
  }

  .setting-content {
    width:320px;
    margin: -22px auto 0;
    word-wrap: break-word;
    color:var(--setting-content-title-subtext-color);

    .button--secondary {
      font-size: 12px;
      height: 24px;
    }
    a.edit.desktop{
      display: none;
    }
    .button--primary {
      font-family: "proximanova";
      background-image: var(--account-setting-update-password-button-background-image);
      background-color: var(--account-setting-update-password-button-background-color);
    }

    .cancel {
      margin-left: 20px;
    }

    .form__help-text {
      color: $red-error-input;
      font-size: 14px;
    }

    table tr th , table tr td {
      border: none;
      border-collapse: separate;
      border-spacing: 0 5px;
      vertical-align: baseline;
    }
    .text-phone-input {
      position: relative;

      @include mobile-tablet {
        input{
          width: 70%;
        }
      }
      @include mobile {
        input{
          width: 77%;
        }
      }
      input.input-error {
        border-color: $red-error-input;
        box-shadow: 0 0 4px 0 rgba($red-error-input, 0.31), inset 0 3px 2px 0 $mystic-shade-2;
        border: solid 2px $red-error-input;
      }

      .alt-text {
        
        color: $white;
        position: absolute;
        right: calc(10px + 100% );
        top: 0px;
        background-color: $blue-bayoux;
        font-size:13px;
        margin: 0;
        width: 227px;
        padding: 7px 14px;
        
        &:after{
          content: 'a';
          position: absolute;
          right: 0px;
          display: block;
          transform: rotate(135deg);
          top: 1px;
          z-index: -1;
          border-left: 6px solid $blue-bayoux;
          border-top: 6px solid $blue-bayoux;
          border-right: 0;
          border-bottom: 0;
        }
      }
      svg {
        position: absolute;
        right: -100px;
        top: 6px;
        font-size: 22px;
        &.fa-check {
          color: $green-progress-completed;
        }
        &.fa-times {
          color: $red-error-input;
        }

      }
      .save-changes {
        // position: absolute;
        min-width: 70%;
        top: 2px;
        right: -68px;
        background-image: var(--account-setting-opt-in-button-background-image);
        background-color: var(--account-setting-opt-in-button-background-color);
       
        @include mobile-tablet{
          right: 0px;
          left: 72%;
        }
        @include mobile{
          right: 0px;
          left: 80%;
        }
      }
    }
    .dropdown {
      position: relative;
      top: 1px;
      border: 1px solid $light-gray;
      border-radius: 3px;
      box-shadow: inset 0 1px 3px $modal-box-shadow-tint;
      padding: 7px;
      margin: 0 0 0 15px;

      &:focus, span:focus {
        outline: none;
      }

      .dropdown-header {
        margin: 0 0 0 5px;
        font-size: 16px;
        font-weight: normal;
        color: $dark-gray;
      }

      .dropdown-options {
        position: absolute;
        top: 36px;
        left: 0px;
        z-index: 2;
        max-height: 150px;
        overflow-y: scroll;
        border-top:1px solid $light-gray;

        .option-choice:hover {
          font-family: proximanova-semi-bold;
          cursor: pointer;
        }
      }
    }

    .arrow {
      display: inline-block;
      margin: 0px 0 0 20px;
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $light-gray;

      &:hover {
        cursor: pointer;
      }
    }


  }

  @include mobile-tablet {

    span {
      margin-left: 16px;
    }
    .setting-content {
      width:auto;
      margin: 20px 16px 0 16px;
    }

    a.edit.desktop {
      display: none;
    }

    span.edit.mobile {
      float: right;
      margin: 0 16px 0 0;
      display: inline-block;
      color: $blue-indicator;
      font-size: 16px;
      display: none;
       &:before {
        content: "o";
        font-family: patient-portal;
        margin-right: 5px;
        width:11px;
        height: 11px;
      }
    }

    .psdRequire {
      margin-left: 16px;
    }

    .psdRequire+.setting-content {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }

  @include mobile {
    span.toggle-text.mobile {
      display: inline-block;
      margin: 2px 0 0 3px
    }

    p.fieldset {
      float: right;
      margin: 0 10px 0 0;
    }
  }

}
.alt-error{
  background: $red-error-input;
  color: $white;
  height: 38px;
  line-height: 38px;
  width: 100%;
  position:fixed;
  top:0;
  left: 0;
  text-align: center;
  font-size: 16px;
  font-family: ProximaNova;
  z-index:103;
  svg{
    fill: $white;
    stroke: $red-error-input;
    margin-right: 10px;
  }
}
.alt-changed{
  background:$green-granted;
  color: $white;
  height: 38px;
  line-height: 38px;
  width: 100%;
  position:fixed;
  top:0;
  text-align: center;
  font-size: 16px;
  font-family: ProximaNova;
  z-index:101;
  left: 0;
  span:nth-child(2){
    color: $white;
    width: 43px;
    height: 38px;
    background-color: $green-granted;
    font-size: 23px;
    padding: 6px 15px 6px 15px;
    float: right;
    line-height: 23px;
    &:hover{
      cursor: pointer;
    }
  }
  svg{
    margin-right: 8px;
  }
}
.separate-line {
  border-bottom: 1px solid $gray-line;
}

.margin1 {
  margin:5px 0 0px 0;
}

.padding1 {
  padding-bottom:10px;
}

.psdRequire {
  margin: 5px 0 0 30px;
}

.psdRequire+.setting-content {
    margin-top: -152px;
}

button.button--disabled, button.button--disabled:hover,
button.button--disabled:focus {
  border: 1px solid $gray-input-border;
  background-color: $light-gray;
  color: $waterloo;
  padding: 6px 12px;
  font-size: 14px;
}

input.disabledInputWidth {

  @media (min-width: 768px) and (max-width: 1224px) {
    width: 70%;    
  }
}


.password-validation-ui {
  ul.list-inline.bullet-list {
    li {
      span {
        font-size: 14px;
        color: $waterloo;
        margin: 0 20px 0 10px;
      }
      
      
      &.checked svg{
        color: $green-progress-completed;
      }
      &.error svg {
        color: $red-error-input;
      }
    }
  }

}
