.checkbox {
  position: relative;
  box-sizing: border-box;
  margin: 1px;

  &__input {
    position: absolute;
    z-index: 1;
    background-color: $white;
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }

  &__overlay {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid $gray-input;
    border-radius: 3px;
    background-color: $white;
    box-shadow: inset 0 1px 3px 0 $gray-shadow-input;
    cursor: pointer;

    &::after {
      opacity: 0;
    }
  }

  &__text {
    margin-left: 10px;
    color: $waterloo;
    vertical-align: top;
    letter-spacing: 0.3px;
    font-weight: 500;
    font-size: 14px;
  }

  &__input:hover + &__overlay {
    border-color: $blue-input-hover;
  }

  &__input:hover + &__overlay + &__text {
    color: $waterloo;
  }

  &__input:checked + &__overlay {
    border-color: $royal-blue;
    background-color: $royal-blue;

    &::after {
      position: absolute;
      top: 4px;
      left: 3px;
      width: 9px;
      height: 5px;
      border: 3px solid $white;
      border-top: none;
      border-right: none;
      background: transparent;
      content: '';
      opacity: 1;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  &__input:checked + &__overlay + &__text {
    color: $waterloo;
  }

  &__input:disabled + &__overlay {
    border-color: $blue-disabled;
    background-color: $white;
    opacity: 0.4;
  }

  &__input:disabled + &__overlay + &__text {
    color: $blue-disabled;
  }

  &__input:focus + &__overlay {
    border-color: $royal-blue;
  }

  &__input:focus + &__overlay + &__text {
    color: $waterloo;
  }

  &__input:checked:focus + &__overlay {
    border: 2px solid;
    border-color: $royal-blue;

    &::after {
      top: 3px;
      left: 2px;
    }
  }

  &__input:checked:focus + &__overlay + &__text {
    color: $blue-focus-label;
  }

  &__input:checked:disabled + &__overlay {
    border-color: $blue-checked-disabled;
    background-color: $blue-checked-disabled;
  }

  &__input:checked:disabled + &__overlay + &__text {
    color: $gray-switch-off;
  }
}
