// Colors
$black                      : #000000;
$dark-violet                : rgba(48, 34, 173, 0);
$dark-violet-low-opacity    : rgba(48, 35, 173, 0.01);
$gray                       : #808080;
$medium-gray                : #999999;
$blue-gray                  : #adb6cc;
$light-gray                 : #dddddd;
$extra-light-gray           : #CACACA;
$whitesmoke                 : #f8f9fb; 
$white                      : #ffffff;
$dark-gray                  : #333333;
$laurel-green               : #008000;
$lightgreen                 : #90ee90;
$green                      : #90ee90;
$dark-cyan                  : #0e506c;
$yale                       : #1c4685;
$indigo                     : #37489e;
$indig-shade-2              : #5679C5;
$navy                       : #1f559b; // substitute for: #3157a2
$purple-blue                : #5679C3;
$amparo-blue                : #5679c4;
$amparo-purple              : #6681B9;
$azure-dull                 : #1565c0;
$azure                      : #0089ff;
$blue-med-opacity           : rgba(46, 129, 197, 0.64);
$light-blue                 : #009ce0;
$lighter-blue               : #18A4FC;
$dark-blue                  : #0073a5;
$blue                       : #0000ff;
$red                        : #ff0000;
$papaya-whip                : #FFECCF;
$sun-shade                  : #FEAB2D;
$linen                      : #FEFCF9;
$ecstasy                    : #F57F17;
$mid-gray                   : #5F6066;
$blue-purple                : #339;
$big-stone                  : #19233C;
$royal-blue                 : #2E34E4;
$royal-blue-shade-2         : #4d52e8;
$royal-blue-shade-3         : #2D34E5;
$hawkes-blue                : #ECF3FE;
$rock-blue                  : #9EA8C7;
$rock-blue-shade-2          : #a0b2ce;
$cornflower-blue            : #608BFF;
$selago                     : #F3F7FE;
$waikawa-gray               : #58799f;
$aluminium                  : #A4A8B4;
$aluminium-shade-2          : #a1a2a5;
$alabaster                  : #FCFCFC;
$alabaster-shade-2          : #FAFAFA;
$dodger-blue                : #4D7CFE;
$dodger-blue-shade-2        : #2697fb;
$dodger-blue-shade-3        : #14afff;
$persian-blue               : #2045B9;
$persian-blue-shade-2       : #1F45B9;
$persian-blue-shade-3       : #272bc8;
$mystic                     : #dce0e9;
$mystic-shade-2             : #E5eaee;
$mystic-shade-3             : #E1E7ED;
$mystic-shade-4             : #E9EEF2;
$tory-blue                  : #11519a;
$catskill-white             : #F9FAFC;
$shark                      : #2F3034;
$shark-2                    : #2A2C33;
$biscay                     : #19396c;
$biscay-shade-2             : #1D3F65;
$coral                      : #ff8056;
$milano-red                 : #d51e0a;
$cloud-burst                : #1C2353;
$blue-haze                  : #ccd2e0;
$blue-bayoux                : #4b5a80;
$shuttle-gray               : #626972;
$oslo-gray                  : #80848b;
$cadet-blue                 : #9DA6C1;
$science-blue               : #026cdc;
$science-blue-shade-2       : #005dbb;
$purple-heart               : #4428b8;
$malibu                     : #7eaff5;
$malibu-shade-2             : #88b7fb;
$portage                    : #928df3;
$ghost                      : #BCBFCC;
$ghost-shade-2              : #CBCBD2;
$waterloo                   : #7d808e;
$waterloo-shade-2           : #848699;
$sub-header-background-color: #30415c;
$jaffa                      : #F58F36;
$chilean-fire               : #F57300;
$botticelli                 : #C4D5DF;
$taupe                      : #483E34;
$nomad                      : #B4AFA4;
$athens-gray                : rgba(234, 237, 241,0.4);
$mercury                    : #E6E6E6;
$athens-gray                : #E5E9EC;
$gray-white                 : #f1f6f8;

$blue-dark-menu             :#19396c; // substitute for: #1d3f65
$blue-fill                  :#eff2fa;
$gray-border-light          :#edf1f4;
$gray-line                  :#e1e7ed;


//component: sidebar
$sidebar-border: 1px solid $mystic-shade-3;
$notification-user-border: 1px solid $sun-shade;

$light-blue-gradient-set: linear-gradient(162deg, $dark-blue 0%, $light-blue 30%, $light-blue 65%, $light-blue 79%, #d3dc3e 100%) no-repeat;
$header-background-mobile-gradient: linear-gradient(263deg, $dark-violet, $dark-violet-low-opacity, $blue-med-opacity), linear-gradient(97deg, $light-blue, $light-blue), linear-gradient($light-blue, $light-blue);

$blue-gradient-set: $indigo 0%, #1a2965 30%, #0d1f50 65%, #112c5e 79%, $yale 100%;
$header-background-mobile: linear-gradient(263deg, $dark-violet, $dark-violet-low-opacity, $blue-med-opacity),
linear-gradient(97deg, #083cb8, #070052),
linear-gradient($sub-header-background-color, $sub-header-background-color);
$button-primary-bg-color-hover  : linear-gradient(126deg, $dodger-blue-shade-2, $royal-blue-shade-2);

$gray-ultra-transparent: rgba(57, 83, 119, 0.1);


// component: button
// portal button gradients -- primary
$button-primary-gradient-degrees: 126deg;
$button-primary-shadow: 0 2px 1px 0 $gray-ultra-transparent;
$button-primary-color-normal: linear-gradient($button-primary-gradient-degrees, $azure, $royal-blue-shade-3);
$button-primary-color-hover: linear-gradient($button-primary-gradient-degrees, $dodger-blue-shade-2, $royal-blue-shade-2);
$button-primary-color-active: linear-gradient($button-primary-gradient-degrees, $science-blue, $persian-blue-shade-3);
$welcome-primary-button-color-hover: var(--welcome-modal-button-hover-bg-color);

//component: secure-messaging
$create-message-box-shadow: 0 2px 1px 0 rgba(58, 83, 119, 0.07);

//component: secure-form
$header-box-shadow: inset 0px 1px 2px 0px $mercury;


// Typography
$din: "DIN";
$din-light: "DINLight";
$din-bold: "DINBold";
$patient-portal: "patient-portal";
$smb: "smb-icon-font";
$smbFile: "smb-file-types";
$proximanova: "proximanova";
$proximanova-thin: "proximanova-thin";
$proximanova-light: "proximanova-light";
$proximanova-semi-bold: "proximanova-semi-bold";
$proximanova-bold: "proximanova-bold";
$proximanova-extra-bold: "proximanova-extra-bold";

$base-font-family   : $proximanova;
$heading-font-family: $base-font-family;

$base-font-family--bold    : $proximanova-bold;
$base-font-family--semibold: $proximanova-semi-bold;
$secondary-font            : $din;
$secondary-font--light     : $din-light;
$secondary-font--bold      : $din-bold;

