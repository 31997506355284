@import '../../variables/color';

.deactivate-pet-modal {
    max-width: 545px;
    font-family: "proximanova";
    &__image {
        width: 155px;
        height: 50px;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 38px;
    }
    &-wrapper {
        padding-right: 49px;
    }
    &__content {
        border-bottom: 1px solid $athens-gray;

        &_title {
            text-align: center;
            color: $dodger-blue;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 16px;
        }
        &_list {
            max-width: 480px;
            padding-left: 51px;
            line-height: 19px;
            color: $mid-gray;
            font-size: 16px;
            padding-bottom: 8px;

            li::before {
                content: "•"; 
                color: $mid-gray;
                margin-right: 15px;
                margin-left: 10px;
            }
            &-desc {
                padding-left: 51px;
                line-height: 19px;
                margin-bottom: 40px;
                max-width: 480px;
            }
        }
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 74px;

        .cancel-btn {
            background-color: $hawkes-blue;
            color: #4D7CFE;
            font-size: 13px;
            border-radius: 5px;
            margin-right: 16px;
        }
        .confirm-btn {
            background-color: $ecstasy;
            color: $white;
            font-size: 13px;
            border-radius: 5px;
            margin-right: 16px;
        }
    }

}