// Font Imports
@import "../fonts/patient-portal/patient-portal";
@import "../fonts/DIN/DIN";
@import "../fonts/proximanova/proximanova";
@import "../fonts/smb/smb";

// Breakpoints 
$medium-screen: 600px;
$large-screen : 900px;

// Font Sizes
$base-font-size: 16px;

/**
 * Font Size at media queries breakpoints
 */
/* Extra small font-size / phone */
$font-size-xs-min: 10px !default;

/* Small font-size / tablet */
$font-size-sm-min: 18px !default;

$button-secondary-appointments: #5679c4  !default;

/* Medium font-size / desktop */
$font-size-md-min: 12px !default;

/* Large font-size / wide desktop */
$font-size-lg-min: 12px !default;

// Line height
$base-line-height   : 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing      : $base-line-height * 1em;
$small-spacing     : $base-spacing / 2;
$base-z-index      : 0;


// Font Colors
$base-font-color: $dark-gray;

// Border
$base-border-color: $light-gray;
$base-border      : 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba($black, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($azure-dull, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing  : ease;
/**
  * end 
**/

/*** Portal Buttons ***/
$base-font-family      : arial !default;
$button-font-size-small: 14px !default;
$button-height-small   : 34px !default;

// Buttons - Secondary //
$button-secondary-color-normal: $white !default;
$button-secondary-color-hover : $whitesmoke !default;
$button-secondary-color-active: silver !default;

// Buttons - Delete //
$button-delete-color-normal    : $red !default;
$button-secondary-text         : $blue !default;
$button-alternate-box-shadow: 0 3px 5px 0 rgba(26, 46, 62, 0.27);

// Buttons - Icons //
$fontastic-icons: ((person, "a", null, null, null), (bell, "b", "Notifications", "Notifications", "Notifications"), (dashboard, "c", "Home", "Home", "Home"), (forms, "d", "Online Forms", "Online Forms", "Online Forms"), (messaging, "e", "Messaging", "Messaging", "Messaging"), (settings, "f", "Account Settings", "Account Settings", "Settings"), (petProfile, "Z", "Pet Profile", "Pet Profile", "Pet Profile"), (credit-card, "g", null, null, null), (financialInfo, "g", "Financial Info", "Financial Info", "Financial Info"), (reply, "i", null, null, null), (trash, "j", null, null, null), (upcoming-calendar, "k", null, null, null), (calendar, "l", null, null, null), (create-message, "m", null, null, null), (create-appointment, "n", null, null, null), (pencil, "o", null, null, null), (printer, "p", null, null, null), (smile, "q", "My Smile", "My Smile", "My Smile"), (appointments, "r", "Appointments", "Appt.", "Appointments"), (hamburger, "s", null, null, null), (more-menu, "t", "More", "More", "More"), (forward-arrow, "u", "Sign Out", "Sign Out", "Sign Out"), (calendar2, "v", "Schedule an Appointments", "Appts.", "Appts."), (speech-bubble, "w", "Refer a Friend", "Refer", "Refer"), (checkmark, "x", "null", "null", "null"), (double-arrows, "y", "null", "null", "null"), (input-error, "z", "null", "null", "null"), (input-warning, "A", "null", "null", "null"), (key, "B", "null", "null", "null"), (envelope, "C", "null", "null", "null"), (close, "D", "null", "null", "null"));


// Dropdown //
$dropdown-text-color: #355164 !default;
$success-color      : #1fd1b9 !default;

// Button sizes //
$button-height         : 34px;
$button-height-small   : 24px;
$button-width          : 100%;
$button-width-icon-only: 45px;
$button-font-size      : 14px;
$button-font-size-small: 12px;

// Font sizes at different viewports //
$button-icon-font-size-desktop: 22px;
$button-icon-font-size-tablet : 22px;
$button-icon-font-size-mobile : 12px;

// Icons //
// font sizes at different viewports
$icon-font-size-hamburger: 26px;
$icon-font-size-desktop  : 23px;
$icon-font-size-tablet   : 31px;
$icon-font-size-mobile   : 29px;
$icon-height-mobile      : 37px;
$label-font-size-mobile  : 11px;
$label-font-size-desktop : 16px;
$label-padding-desktop   : 0 0 0 20px;

// colors
$icon-class : icon-label__icon;
$label-class: icon-label__label;

// default colors
$icon-link        : $blue-gray !default;
$icon-active      : lighten($blue-gray, 5%) !default;
$icon-hover       : lighten($blue-gray, 10%) !default;
$icon-label-link  : lighten($blue-gray, 15%) !default;
$icon-label-active: $white !default;
$icon-label-colors: ((null, $icon-class, $icon-link, $icon-active, $icon-hover, $icon-link), (null, $label-class, $icon-label-link, $icon-label-active, $icon-hover, $icon-label-link), ("active", $icon-class, $icon-active, $icon-active, $icon-active, $icon-active), ("active", $label-class, $icon-label-active, $icon-label-active, $icon-label-active, $icon-label-active));

// Switch //
$switch-label-line-height     : 22px !default;
$switch-label-font-size       : 12px !default;
$switch-label-font-size--small: 10px !default;
$switch-label-font-size--big  : 14px !default;
$switch-checked-gradient      : linear-gradient(137deg, $azure, $royal-blue) !default;


/*** Forms ***/
// Variables
// button sizes
$button-height         : 34px;
$button-height-small   : 24px;
$button-width          : 100%;
$button-width-icon-only: 45px;
$button-font-size      : 14px;
$button-font-size-small: 12px;

// font sizes at different viewports
$button-icon-font-size-desktop: 22px;
$button-icon-font-size-tablet : 22px;
$button-icon-font-size-mobile : 12px;


// Navigation - Secondary //
/*sub-header width*/
$sub-header-width: 100%;

/*sub-header height*/
$sub-header-desktop-height: 242px;
$sub-header-tablet-height : 220px;
$sub-header-mobile-height : 153px;


/*font-sizes*/
$slogan-font-size         : 32px;
$phone-desktop-font-size  : 18px;
$phone-tablet-font-size   : 14px;
$phone-mobile-font-size   : 24px;
$email-desktop-font-size  : 18px;
$email-tablet-font-size   : 14px;
$email-mobile-font-size   : 14px;
$address-desktop-font-size: 18px;
$address-tablet-font-size : 14px;
$address-mobile-font-size : 14px;


/*** Modules ***/
// Modules - Appointments //

// define the class names associated with calendar icon color values
// base, stroke, fill
$calendar-icons: ((completed, $white, lightblue, $blue), (confirmed, $white, $lightgreen, $laurel-green), (unconfirmed, $white, lightpink, $red)) !default;

/*** devices ***/
$small-mobile-width: 375px;
$tablet-width      : 768px; // min tablet (tablet - 1 = mobile max)
$desktop-width     : 1225px; // desktop - 1 = tablet max

@mixin mobile {
  @media (max-width: #{$tablet-width - 1}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width - 0}) and (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

// For both mobile and tablet
@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

/*** Spinner ***/
$spinner-dot-bg: var(--loading);
$spinner-dot-size: 8px;
$spinner-dot-size-sm: $spinner-dot-size / 2;
$spinner-dot-size-lg: $spinner-dot-size * 2;

// ----------------------------
// color defintions
// ----------------------------

// calendar icons
$green-fill: #f2fdf2;
$green-stroke: #a2cca2;
$red-fill: #fff4f3;
$red-stroke: #d5aeae;
$blue-fill: #eff2fa;
$blue-stroke: #aebbc7;

// greens
$green-granted: #40ca4f; // substitute for: replaces #4ac748
$green-progress-completed: #1ed78e;

// yellows
$yellow-warning: #ffbe39;

// reds
$red-error-input: #f4584c;	// substitute for: #fd6962

// blacks
$black-gray-text: #25292d;	// substitute for: #2a2c33
$black-semi-transparent: rgba(0, 0, 0, 0.8);
$black-login-input: #191919;

// whites
$white-transparent: rgba(255, 255, 255, 0);
$white-active: #e9f2fe;	// active color, secondary button
$white-semi-transparent: rgba(255, 255, 255, 0.5);

// blues
$blue-completed: #1b508d;
$blue-button-light: #eaeefd;
$blue-disabled: #b3c5d2;
$blue-focus-label: #566986;
$blue-checked-disabled: #9dbbf4;
$blue-dark-menu: #19396c;	// substitute for: #1d3f65
$blue-indicator: #2045b9;
$blue-input-hover: #a2a6bc;
$blue-gradient-set: $indigo 0%, #1a2965 30%, #0d1f50 65%, #112c5e 79%, $yale 100%;

// grays
$gray-modal-background: rgba(142, 142, 142, 0.8);
$gray-shadow-user: rgba(65, 73, 88, 0.5);
$gray-border: #e5e9ec;
$gray-border-light: #edf1f4;
$gray-shadow-input: rgba(144, 154, 164, 0.3);
$gray-input: #bcbfcc;
$gray-input-border: #cbcbd2;
$gray-border-module-content: #ced7dd;
$gray-input-disabled-background: #ebecef;
$gray-switch-off: #bcc9d8;		// substitute for: #bac3ce
$gray-password-required: #646470;
$gray-default: #798fae;
$gray-line: #e1e7ed;
$gray-close: #c9d5e1;
$gray-login-border: #828b88;



// ----------------------------
// colors applied to elements
// ----------------------------
$header-background-mobile-box-shadow: 2px 0 0 0 rgba(20, 40, 54, 0.1);
$modal-box-shadow-direction: 0 20px 24px 0;
$modal-box-shadow-tint: rgba(0, 0, 0, 0.1);

// footer
$footer-links-hover: darken($white-semi-transparent, 30%);


/// ************ only self-compiling components below this line *********/
// ** components below this line will self-compile
// ** other components have remaining dependencies
// component: calendar-icon
// class name override here not intended
$calendar-icons: (
                ( appointments__item--completed, $white, $blue-fill, $blue-stroke ), ( appointments__item--confirmed, $white, $green-fill, $green-stroke ), ( appointments__item--unconfirmed, $white, $red-fill, $red-stroke )
);
// portal button flat color -- secondary
$button-secondary-color-normal: $white;
$button-secondary-color-hover: $selago;
//whats this ? this have two references
$button-secondary-color-active: $white-active;

// portal button gradient -- delete
// currently no hover or active colors defined. using default for all three
$button-delete-color-normal: linear-gradient(156deg, $coral, $milano-red);
$button-secondary-text: linear-gradient(101deg, $science-blue-shade-2, $purple-heart);


// component: switch
$switch-label-line-height: 22px;
$switch-label-font-size: 12px;
$switch-label-font-size--small: 10px;
$switch-label-font-size--big: 14px;
$switch-checked-gradient: linear-gradient(137deg, $azure, $royal-blue);

// component: reset-password
$reset-password-background-degrees: 162deg;
$reset-password-form-width: 600px;

// component: reset-password-confirm
$reset-password-confirm-background-degrees: 162deg;
$reset-password-confirm-form-width: 490px;
$reset-password-confirm-form-min-width: 260px;


// Radicals: Not in styleguide
$radical-background-after: linear-gradient($malibu, $portage);
