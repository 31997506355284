.form {
  position: relative;
  box-sizing: border-box;
  width: 350px;

  & &__input {
    margin-top: 4px;
    height: 34px;
    border-color: $gray-input-border;
    background-color: $white;
    color: $gray-input;
    font-family: $base-font-family;
  }

  & &__text,
  & &__help-text {
    color: $blue-focus-label;
    font-size: 14px;
    font-family: $font-family-bold;
  }

  & &__help-text {
    font-family: $base-font-family;
  }

  & &__input:focus {
    border-color: $royal-blue;
    box-shadow: none;
    color: $black;
    font-family: $base-font-family;
  }

  & &__input:disabled {
    background-color: $gray-input-disabled-background;
    box-shadow: none;
  }

  &--confirmed {
    .form {
      &__input {
        border-color: $green-granted;
      }

      &__help-text {
        color: $green-granted;
      }
    }

    &::after {
      color: $green-granted;
      content: 'x';
    }
  }

  &--warning {
    .form {
      &__input {
        border-color: $yellow-warning;
      }

      &__help-text {
        color: $yellow-warning;
      }
    }

    &::after {
      color: $yellow-warning;
      content: 'x';
    }
  }

  &--error {
    .form {
      &__input {
        border-color: $red-error-input;
      }

      &__help-text {
        color: $red-error-input;
      }
    }

    &::after {
      color: $red-error-input;
      content: 'z';
    }
  }

  &--confirmed,
  &--warning,
  &--error {
    &::after {
      position: absolute;
      top: 36px;
      right: 16px;
      display: block;
      font-family: 'patient-portal';
    }
  }

  &--smallest {
    width: 75px;
  }

  &--smaller {
    width: 165px;
  }

  &--small {
    width: 250px;
  }

  &--large {
    width: 500px;
  }

  &--larger {
    width: 650px;
  }
}
