.in-active-user {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 91px;
  font-size: 16px;
  color: $black;
  .in-active-user__boxLeft {
    z-index: 2;
    .in-active-user__header {
      font-size: 30px;
      font-weight: 900;
      text-shadow: 0px 0px $black;
    }
    .in-active-user__btnContainer {
      margin: 40px 0px;
      .in-active-user__btn{
        color: $white;
        background: $lighter-blue;
        border-radius: 3px;
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 100;
      }
    }
    .in-active-user__busData {
      font-size: 16px;
      .in-active-user__busName{
        margin-bottom: 24px;
      }
    }
  }
  .in-active-user__boxRight{
    z-index: 1;
    position: absolute;
    right: 0%;
    padding-right: 91px;
    .in-active-user__img {
      user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
     }
  }
}

@include tablet {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__subText{
        font-size: 20px;
      }
    }
    .in-active-user__boxRight{
      max-width: 65%;
    }
  }
}

@include mobile {
  .in-active-user {
    display: block;
    margin-top: 50px;
    padding: unset;
    text-align: center;
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 250px;
      }
      .in-active-user__subText{
        font-size: 18px;
        line-height: 1;
        padding: 0 50px;
        .in-active-user__subText-num {
          display: block;
        }
      }
      .in-active-user__busData {
        line-height: 1;
      }
    }
    .in-active-user__boxRight{
      top: 9%;
      right: 0%;
      left: 0%;
      padding: 0px 17%;
    }
  }
}

@media (min-device-width: 2560px) {
  .in-active-user {
    justify-content: center;
    .in-active-user__boxLeft {
      .in-active-user__subText{
        font-size: 24px;
      }
    }
    .in-active-user__boxRight{
      position: relative;
    }
  }
}

@media (max-device-width: 717px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 250px;
      }
      .in-active-user__subText{
        padding: 0 225px;
      }
    }
    .in-active-user__boxRight{
      top: 10%;
      right: 0%;
      left: 0%;
      padding: 0px 30%;
    }
  }
}

@media (max-device-width: 653px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 250px;
      }
      .in-active-user__subText{
        padding: 0 165px;
      }
    }
    .in-active-user__boxRight{
      top: 19%;
      right: 0%;
      left: 0%;
      padding: 0px 27%;
    }
  }
}

@media (max-device-width: 600px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 250px;
      }
      .in-active-user__subText{
        padding: 0 165px;
      }
    }
    .in-active-user__boxRight{
      top: 5%;
      right: 0%;
      left: 0%;
      padding: 0px 25%;
    }
  }
}

@media (max-device-width: 540px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 250px;
      }
      .in-active-user__subText{
        padding: 0 135px;
      }
    }
    .in-active-user__boxRight{
      top: 8%;
      right: 0%;
      left: 0%;
      padding: 0px 24%;
    }
  }
}

@media (max-device-width: 414px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 260px;
      }
      .in-active-user__subText{
        padding: 0 70px;
      }
    }
    .in-active-user__boxRight{
      top: 7%;
      right: 0%;
      left: 0%;
      padding: 0px 12%;
    }
  }
}

@media (max-device-width: 375px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 250px;
      }
      .in-active-user__subText{
        padding: 0 50px;
      }
    }
    .in-active-user__boxRight{
      top: 8%;
      right: 0%;
      left: 0%;
      padding: 0px 12%;
    }
  }
}

@media (max-device-width: 320px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__subText{
        padding: 0 30px;
      }
    }
    .in-active-user__boxRight{
      top: 9%;
      right: 0%;
      left: 0%;
      padding: 0px 6%;
    }
  }
}

@media (max-device-width: 280px) {
  .in-active-user {
    .in-active-user__boxLeft {
      .in-active-user__header {
        margin-bottom: 225px;
      }
      .in-active-user__subText{
        padding: 0 30px;
      }
    }
    .in-active-user__boxRight{
      top: 9%;
      right: 0%;
      left: 0%;
      padding: 0px 6%;
    }
  }
}