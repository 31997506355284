.errorContainer {

  img {
    margin-top: -40px;
  }
}

.errorBoldFont {
  font-size: 28px;
  font-weight: bold;
  color: $shuttle-gray;
}

.errorFont {
  font-size: 18px;
  color: $shuttle-gray;
}

.errorLink {
  font-size: 13px;
  color: $dodger-blue-shade-3;
}
