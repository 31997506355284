.header {
  position: fixed; //*** Causing issue with astrum display
  top: 0;
  z-index: $z-index-header;
  min-width: $mobile-min-width;
  width: $header-width;
  height: $header-height;
  border-bottom: 1px solid $gray-line;
  display: block;

  .header__schedule-appointment {
    float: right;
    margin-right: 16px;
    line-height: 50px;

    .button--secondary {
      box-shadow: none;
      vertical-align: middle;
    }
  }

  .user-notification {
    float: right;
    margin-right: 24px;
    line-height: 50px;
    position: relative;

    &:hover {
      cursor: pointer;
    }
    .header-new-notifications {
      position: absolute;
      font-size: 10px;
      color: $white;
      height: 12px;
      line-height: 12px;
      background: $red-error-input;
      border-radius: 15px;
      text-align: center;
      font-weight: normal;
      top: 8px;
      left: 11px;
      padding: 0 6px;
    }
  }

  &-user-info{
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    right: 0
  }

  .practice-name {
    float: left;
    margin-left: 16px;
    color: $white;
  }

  .showNone{
    display: none
  }
  @include desktop{
    .mobileViewName{
      display: none;
    }
  }
  @include mobile{
    .mobileViewName{
      display: block;
    }
    .practice-name{
      display: none;
    }
    #header-apps {
      float: left;
      padding-left: 15px;
    }
  }
  @include tablet{
    .mobileViewName{
      display: none;
    }
  }
  .header-apps {
    display: inline-block;
    float: right;
    padding-top: 8px;
    position: relative;
  }

  .user-notification-modal {
    top: $header-height;
    right: 16px;
    margin-right: auto;
    margin-left: auto;
  }

  &--type-2 {
    margin: 0;
    width: 100%;
    background: $black-semi-transparent;

    .practice-name {
      color: $white;
      font-family: $secondary-font--bold;
    }
  }
}

.responsiblePartyUserInfo{
  display: flex;
  justify-content: center;
  align-items: center;
  
  @include mobile{
    margin-right: 10px;
  }
  &-loggedInUser{
    padding-right: 10px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 3rem;
    max-width: 6rem;
    text-align: center;
    color: var(--header-username-color, $button-secondary-appointments);
    &:hover{
      cursor: pointer;
    }

  }
  &-caret{
    color: var(--header-username-caret-color, #6681B9);
  }

  &-displayDependents{
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 3px 5px 0 rgba(26, 46, 62, 0.27);
    display: none;
    position: absolute;
    top: 40px;
    right: 40px;
    min-width: 279px;

    @include mobile-only {
      min-width: 225px;
    }
  }
  &-showDropdown{
    display: block
  }
  &-dependentInfo{
    display: flex;
    justify-content: row;
    &-check{
      margin: 4px 10px;
      color: $success-color;
      opacity: 1;
    }
    &-name{
      color: $gray-password-required;
      font-size: 14px;
      cursor: pointer;
    }
  }
  &-selectName{
    display: flex;
    justify-content: space-between;
  }
  &-patientPortalNoAccess{
    border: 1px solid $gray-border;
    background-color: #FBFCFD;
    padding: 10px 15px 0px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &-patientPortalAccess{
    padding: 10px 15px 0px 0px;
  }
  .hide{
    opacity: 0;
  }
  .createAcc{
    background: -webkit-linear-gradient(0deg, #0089FF, #2D34E5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12px;
    font-weight: 700;
    margin-left: 10px;
    cursor: pointer;
  }
  .accessRestricted{
    color: #6B6B77;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
    &-icon{
      color: #4F78CA;
    }
  }
}
@include mobile-only {
  .header {
    background-image: var(--header-mobile-background-image);
    background-color: var(--header-mobile-background-color);
    box-shadow: $header-background-mobile-box-shadow;
    left: 0;
    position: relative;

    .header__schedule-appointment {
      width: 89px;

      .button--secondary {
        border: 1px solid $navy;
        background-color: $navy;
        color: $white;
        text-align: center;
        white-space: normal;
        font-size: $button-font-size-small;
        font-family: $font-family-semi-bold;
        line-height: 12px;

        &::before {
          display: none;
        }
      }
    }

    &--type-2 {
      margin: 0;
      width: 100%;
      background: $black-semi-transparent;

      .practice-name {
        color: $white;
        font-family: $secondary-font--bold;
      }
      .mobileViewName{
        display: block;
      }
      .desktopViewName{
        display: none;
      }
    }
  }
}

// Special case for smaller mobile
@media (max-width: 350px) {
  .header {
    .header__schedule-appointment {
      margin-right: 1px;
    }
  }
}


@include tablet {
  .header {
    margin-left: $menu-tablet-width;
    width: calc(#{$header-width} - #{$menu-tablet-width});
    background-color: var(--header-background-color);

    .header__schedule-appointment {
      margin-right: 18px;
      width: 220px;
    }

    .user-notification {
      margin-right: 20px;
    }

    .practice-name {
      margin-left: 30px;
      color: var(--header-practice-name-text-color);
    }

    &--type-2 {
      margin: 0;
      width: 100%;
      background: $black-semi-transparent;

      .practice-name {
        color: $white;
        font-family: $secondary-font--bold;
      }
    }
  }
}

@include desktop {
  .header {
    margin-left: $menu-desktop-width-expanded;
    width: calc(#{$header-width} - #{$menu-desktop-width-expanded});
    background-color: var(--header-background-color);
    position: absolute;

    transition: all 0.5s ease-in-out;

    .header__schedule-appointment {
      margin-right: 0;
      text-align: right;

      .button--only-icon {
        width: $button-width-icon-only;
        border-width: 0;
        font-size: 0;

        &::before {
          margin: 0;
        }

        &:hover {
          padding: 0;
          width: $button-width;
          border-width: 1px;
          font-size: $button-font-size;

          &::before {
            margin-right: 10px;
          }
        }
      }
    }

    .user-notification {
      margin-left: 12px;
    }

    .user-notification-modal {
      top: $header-height;
      margin-right: auto;
      margin-left: auto;
    }

    &--type-2 {
      margin: 0;
      width: 100%;
      background: $black-semi-transparent;

      .practice-name {
        color: $white;
        font-family: $secondary-font--bold;
      }
    }
  }
}

// Practice Name //
.practice-name {
  font-size: var(--hedaer-practice-name-font-size);
  font-family: var(--hedaer-practice-name-font-family);
  font-weight: var(--hedaer-practice-name-font-weight);
  line-height: 50px;
}

// User Notification //
.user-notification {
  position: relative;

  &::before {
    display: block;
    color: var(--header-desktop-user-notification-color);
    content: "a";
    font-size: 24px;
    font-family: $patient-portal;

    @include mobile-only{
      color: var(--header-mobile-user-notification-color);
    }
  }
}

.user-notification__alert {
  position: absolute;
  top: 5px;
  right: -7px;
  visibility: hidden;
  padding: 3px 6px;
  border-radius: 10px;
  background-color: $red-error-input;
  color: $white;
  text-align: center;
  font-size: $user-notification-alert-font-size;
  line-height: $user-notification-alert-font-size;
}

.user-notification--alert-visible {
  .user-notification__alert {
    visibility: visible;
  }
}

// User Notification Modal //
.user-notification-modal {
  position: absolute;
  z-index: $z-index-notification-modal;
  display: none;
  max-width: 344px;
  width: 90%;
  border-radius: 4px;
  background: $white;
  box-shadow: 0 7px 27px 0 $gray-shadow-user;
}
.user-notification-modal , .user-notification-page{
  .notification-section {
    border-top: solid $gray-border-light 1px;
    color: $cadet-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    .notification-section-header{
      font-family: proximanova;

      .notification-bell-wrapper {
        font-size: 20px;
        padding-left: 25px;
      }
      .notification-title-text{
        margin-left: 10px;
      }
      .new-notifications{
        background-color: $red-error-input;
        color: $white;
        border-radius: 15px;
        font-size: 14px;
        padding: 4px 10px;
        margin-left: 10px;
      }
      .angle-down{
        float: right;
        cursor: pointer;
        margin-right: 15px;
        font-size: 20px;
        margin-top: 6px;
      }
    }
  }
  .notification-list {
    -webkit-overflow-scrolling: touch;
    border-top: solid var(--notification-list-border-color) 1px;
    background-color: var(--notification-modal-bg-color);
    max-height: 50vh;
    overflow-y: auto;

    @media (max-width:767px) {
      height: 25vh;
    }

    button.notification-button{
      width: 90%;
    }
    .notification-item-container {
      cursor: pointer;
      &:first-child{
        .notification-item {
          border:0;
        }
      }
    }
    .notification-item-container-read {
      background-color: var(--notification-status-read-color);
    }
    .notification-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 90px;
      width: 80%;
      margin-left: 17%;
      border-top: solid var(--notification-list-border-color) 1px;
      position:relative;
      &:first-child{
        &::after{
          border-left: 0px solid $mystic;
        }
      }

      &.notification-read::before{
        border: 4px solid $gray-input;
      }
      &::before {
        content: '';
        height: 15px;
        width: 15px;
        display: inline-block;
        margin-top: 11px;
        position: absolute;
        background: $selago;
        border: 4px solid $red-error-input;
        border-radius: 10px;
        top: 14px;
        left: -34px;
      }
      &::after{
        height: 66px;
        display: inline-block;
        width: 2px;
        content: '';
        border-left: 1px solid $mystic;
        top: -62px;
        left: -27px;
        position: absolute;
      }
      .notification-type {
        color: $mid-gray;
        font-weight: bold;
        font-size: 13px;
        line-height: 8.5px;
      }
      .notification-time {
        font-size: 11px;
        padding-right: 8px;
        padding-top: 5px;
        margin-top: 15px;
        color: $extra-light-gray ;
        position: absolute;
        top: 0;
        right: 0;
        .fa-clock {
          display: none;
        }
      }
      .notification-message{
        color: $mid-gray;
        font-size: 13px;
      }
      .notification-status {
        font-size: 11px;
        .notification-read {
          color: $green-granted;
          svg {
            margin-right: 5px;
          }
        }
        .check {
          margin-right: 5px;
          font-size: 12px;
        }
        .notification-delete{
          display: none;
        }
      }
    }
  }
}
.empty-notification{
  height: 257px;
  text-align: center;
  padding: 25px 82px;
  user-select: none;

  span{
    display: block;
    text-align: center;
    font-family: AppleSystemUIFont;
    text-align: center;
    color: $shuttle-gray;
  }
  p{
    text-align: center;
    font-family: AppleSystemUIFont;
    text-align: center;
    color: $shuttle-gray;
  }
}

.user-notification-page {
  .user-notification-page-title {
    font-size: 18px;
    color: $blue-dark-menu;
    padding: 18px 0px 21px 30px;
    background-color: $selago;
    border-top: 1px solid $gray-line;
  }
  .notification-list {
    background-color: $white;
    .notification-item {
      width: inherit;
      margin-left: 60px;
      padding-top: 15px;
      &::before{
        top: 23px;
      }
      &::after{
        border-left: 4px solid $mystic;
        top: -47px;
        left: -29px;
      }
      .notification-time {
        float: none;
        position: relative;
        font-size: 12px;
        .fa-clock {
          margin-right: 6px;
          display: inline-block;
        }
       }
       .notification-status{

        position: absolute;
        top: 20px;
        right: 0px;
        .notification-delete{
          font-size: 20px;
          display: inline-block;
          color: $amparo-blue;
          margin-left: 29px;
          margin-right: 27px;
          cursor: pointer;
        }
        input[type="checkbox"]{
          display: none;
        }
        .notification-unread {
          @extend .button--primary;

        }
        .notification-read{
          @extend .button--secondary;
          color: $amparo-blue;
          svg {
            margin-right: 5px;
          }
        }
       }
    }

  }
  .two-columns {
    // 50px is header height i can't acces $header-height here
    // 67px is the height of the Notification Center bar
    min-height: calc(100vh - 50px - 67px);

    .column-70 {
      min-height: inherit;
      display:inline-block;
      width: 70%;
      border-right: 1px solid $gray-line;
      vertical-align: top;
    }
    .column-30 {
      border-top: 1px solid $gray-line;
      vertical-align: top;
      min-height: inherit;
      display:inline-block;
      width: 30%;
      .location-component {
        width: 90%;
      }
    }
  }
}

.user-notification-modal_head {
  text-align: center;
}

.user-notification-modal__user-name,
.user-notification-modal__user-email {
  display: block;
  word-wrap: break-word;
  padding: 0 10px;
}

.user-notification-modal__user-email {
  color: var(--notification-user-email-color);
}

.user-notification-modal__user-name {
  color: var(--notification-user-text-color);
}

.user-notification-modal__user-pic {
  position: relative;
  width: 20px;
  height: auto;
}

.user-notification-modal__user-pic-container {
  display: flex;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 14px;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background: var(--notification-user-background-color);
  border: var(--notification-user-border);
  align-items: center;
  justify-content: center;
}

.user-notification-modal_footer {
  font-family: $base-font-family;

  .icon-label {
    cursor: pointer;
    color: $cadet-blue;

    .icon-label__label {
      display: inline-block;
      padding-left: 10px;
      font-size: 16px;
      font-family: $base-font-family;
    }

    .icon-label__icon {
      display: inline-block;
      font-size: 26px;
    }
  }
}

.user-notification-modal__alert {
  margin-left: 4px;
  padding: 3px 5px;
  border-radius: 9px;
  background: $red-error-input;
  color: $white;
  font-size: 12px;
}

.user-notification-modal__row {
  padding: 11px 19px;

  &:first-child {
    border-top: solid $gray-border-light 1px;
    border-bottom: solid $gray-border-light 1px;
  }
}

.user-notification-modal--show {
  display: block;
}

.user-notification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-notification-modal-overlay;
  display: none;
  width: 100%;
  height: 100%;
  background: transparent;
}

.user-notification-modal.show-modal,
.user-notification-modal-overlay.show-modal {
  display: block;
}

@include desktop {
  .user-notification-modal {
    width: 287px;
  }
}

@include mobile{
  .header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}