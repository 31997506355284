.button--secondary.button__icon {
  &.gradient {
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 1px 0 $gray-ultra-transparent;
    border: var(--appointments-button-second-print-border);
    color: $amparo-blue;
    font-family: ProximaNova;
    background-color: var(--appointments-button-second-request-appointment-background-color);

    /* Print */
    &.print {
      &:before {
        content: "p";
        font-family: patient-portal;
      }

      span {
        margin-left: 5px;
      }
    }

    span {
      color: $amparo-blue;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
