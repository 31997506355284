/* Patient Forms */
.patient-forms {
  @include mobile-only {
    .module {
      &__content {
        border: none;
      }
    }

    &__table-headings,
    &__status {
      display: none;
    }
  }

  &__form {
    display: flex;
    padding: 12px 15px;
    border-bottom: 1px solid $gray-border;
    background-color: $white;
    justify-content: space-between;

    @include mobile-only {
      margin-bottom: 16px;
      border: 1px solid $gray-border-module-content;
      border-radius: 4px;
    }

    .financial {
      margin-right: 12px;
      padding: 4px 11px;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      background-color: $whitesmoke;

      .icon-label__icon {
        color: $aluminium-shade-2;
      }
    }

    .financial,
    .pencil,
    .trash {
      .icon-label__icon {
        font-size: 21px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.in-progress {
      .more-menu {
        display: none;
      }
    }

    &.submitted {
      .patient-forms__status {
        color: $shuttle-gray;
      }

      .pencil,
      .trash {
        display: none;
      }
    }
  }

  &__details {
    overflow: hidden;
    width: 100%;

    @include mobile-only {
      padding-right: 15px;
    }
  }

  &__form-name,
  &__last-modified {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__form-name {
    color: $blue-dark-menu;
    font-size: 16px;

    @include mobile-only {
      margin: 2px 0;
      line-height: 1;
    }
  }

  &__last-modified {
    color: $shuttle-gray;
    font-size: 14px;
  }

  &__actions {
    display: flex;
    padding-left: 24px;
    min-width: 95px;
    align-items: center;
    justify-content: space-between;

    @include mobile-only {
      border-left: 1px solid $gray-border;
    }
  }
}

@include tablet {
  .patient-forms {
    &__table-headings {
      padding: 13px 20px;
      border-bottom: 1px solid $gray-border;
      background-color: $white;
    }

    &__form {
      padding: 0 20px;
      height: 64px;

      .icon-label.financial {
        display: none;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__details,
    &__table-headings {
      display: flex;
      align-items: center;
    }

    &__form-name,
    &__last-modified,
    &__status {
      font-size: 14px;
    }

    &__form-name,
    &__form-name-label,
    &__last-modified,
    &__last-modified-label {
      width: 40%;
    }

    &__status,
    &__status-label {
      width: 20%;
      color: $blue-dark-menu;
    }

    &__actions-label {
      padding-left: 24px;
      min-width: 95px;
    }

    &__form-name-label,
    &__last-modified-label,
    &__status-label,
    &__actions-label,
    .double-arrows .icon-label__icon {
      color: $shuttle-gray;
      opacity: 0.5;
    }

    &__form-name-label,
    &__last-modified-label,
    &__status-label,
    &__actions-label {
      font-size: 13px;
      font-family: $font-family-semi-bold;
    }

    .double-arrows .icon-label__icon {
      margin-left: 8px;
      font-size: 10px;
    }
  }
}
