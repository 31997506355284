// Menu //
.menu {
  position: fixed; //*** Causing issue with astrum display
  right: 0;
  bottom: 0;
  left: 0;
  border-right: var(--sidebar-navigation-border-right-color);
  z-index: $z-index-menu;
  height: $menu-mobile-height;
  background-color: var(--sidebar-navigation-background-fallback);
  background: var(--sidebar-navigation-background);
  display: flex;

  // DON'T TRIP, TEMPORARY.

  .menu__container {
    display: flex;

    .menu__element {
      display: flex;
      width: 100%;
      padding: 0 20px;
      .menu__link {
        width: 100%;

        // Specifically targeting links on the left menu to
        // prevent breaking style of other elements (many use
        // the icon-label class).

        .icon-label__label,
        .icon-label__icon {
          color: var(--sidebar-navigation-link-not-active-icon-color);
        }

        .icon-label__label {
          font-family: $proximanova;
        }
      }
    }

    .menu__element--desktop,
    .menu__element--mobile {
      display: none;
    }
  }
}

@include mobile-only {
  .menu {
    min-width: $mobile-min-width;

    .menu__container {
      width: 100%;
      align-items: center;

      .menu__element {
        padding: 0 10px;
      }

      .menu__position {
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
      }
      
      .menu__element.mobile-modal {
        display: none;
      }

      .menu__element.active {
        .menu__link {

            .icon-label__label {
              color: var(--sidebar-navigation-link-label-color-active);
              font-weight: 400;
            }

            .icon-label__icon {
              color: var(--sidebar-navigation-link-icon-color-active);
            }
          }
        
      }

      .menu__element--mobile {
        display: flex;
        width: 100%;
        padding: 0 15px;

        .menu__link {
          width: 100%;
        }

        .active {
          .icon-label__label {
            color: var(--sidebar-navigation-link-label-color-active);
            font-weight: 700;
          }

          .icon-label__icon {
            color: var(--sidebar-navigation-link-icon-color-active);
          }
        }
      }
    }
  }
}

@include tablet {
  .menu {
    display: flex;
    flex-direction: column;
    width: $menu-tablet-width;
    height: $menu-tablet-height;

    .menu__container {
      flex-direction: column;
      padding: 20px 0 0;
      text-align: center;

      .menu__element {
        padding: 0px;
        margin-bottom: 10px;

        &.active {
          background-color: var(--sidebar-navigation-link-background-active);
          border-radius: var(--sidebar-navigation-link-background-active-border-radius);
          .menu__link {
            .icon-label__label {
              color: var(--sidebar-navigation-menu-text-color-active);
              font-weight: 700;
            }
  
            .icon-label__icon {
              color: var(--sidebar-navigation-link-icon-color);
            }
          }
          
        }

        .menu__link {
          text-align: center;
          .icon-label__label{
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@include desktop {
  .menu {
    width: $menu-desktop-width-expanded;
    height: $menu-desktop-height;

    transition: all 0.5s ease-in-out;

    .menu__container {
      padding-top: 12px;

      .menu__element {
        padding: 0 20px;
        .menu__link {
          &:hover {

            .icon-label__label {
              color: var(--sidebar-navigation-link-label-color-hover);
            }

            .icon-label__icon {
              color: var(--sidebar-navigation-link-icon-color-hover);
            }
          }
          &.active {
            .menu__link {
              .icon-label__label {
                font-weight: 700;
              }
            }
          }
          overflow-x: hidden;
          text-align: left;
          white-space: nowrap;
        }

        .icon-label .icon-label__label {
          transition: visibility 1s ease-in-out, opacity 1s ease-in-out;
        }
      }

      .menu__element--desktop {
        display: flex;
        padding-bottom: 18px;
        padding-left: 20px;

        .menu__trigger {
          display: inline-block;
          width: 26px;
          height: 40px;
        }
      }
    }
  }
}

// Collapsed //
@include desktop {
  .collapsed {
    .menu {
      width: $menu-desktop-width-collapsed;

      .menu__container {
        .menu__element {
          position: relative;
          padding: 0 20px;
          .icon-label .icon-label__label {
            display: none;
          }
        }
      }
    }

    .header,
    .sub-header {
      margin-left: $menu-desktop-width-collapsed;
      width: calc(#{$header-width} - #{$menu-desktop-width-collapsed});
    }

    .content {
      margin: 0 0 0 $menu-desktop-width-collapsed;
      width: calc(100% - #{$menu-desktop-width-collapsed});
    }
  }
}

// More menu mobile //
@include mobile-only {
  .more-menu-modal {
    position: fixed; //*** Causing issue with astrum display
    right: 0;
    bottom: 10px + $menu-mobile-height;
    left: 0;
    z-index: $z-index-more-menu-modal;
    margin: auto;
    width: 95%;
    border: solid 1px $oslo-gray;
    border-radius: 4px;
    background-color: $white;

    .more-menu-modal-content {
      .more-menu__list li {
        width: 100%;
        border-bottom: solid 1px $gray-border-light;

        .menu__link {
          display: flex;
          padding: 13px 18px;

          .icon-label__icon{
            color: var(--sidebar-navigation-mobile-label-icon-color);
          }

          .icon-label__label {
            color: var(--sidebar-navigation-mobile-label-color);
          }

          .icon-label__label {
            padding: 10px 12px 0px 12px;
            font-weight: normal;
            font-size: 16px;
            font-family: $base-font-family;
          }
        }
      }
    }
  }

  .more-menu-modal-background {
    position: fixed; //*** Causing issue with astrum display
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .more-menu-modal.show-modal,
  .more-menu-modal-background.show-modal {
    display: block;
  }

  .more-menu-modal-background.show-modal {
    background-color: $gray-modal-background;
    z-index: 10;
  }
}

// Tooltips
@include desktop {
  .collapsed {
    .menu {
      .menu__container {
        .menu__element {
          .icon-label {
            .icon-label__label {
              position: absolute;
              top: 5px;
              left: 60px;
              z-index: $z-index-tooltips;
              padding: 5px 5px 5px 10px;
              width: 120px;
              border-radius: 2px;
              background-color: $blue-dark-menu;
              color: $white;
              font-size: 12px;
            }

            .icon-label__label::after {
              position: absolute;
              top: 50%;
              right: 100%;
              margin-top: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent $blue-dark-menu transparent transparent;
              content: "";
            }
          }

          .icon-label:hover {
            .icon-label__label {
              display: block;
            }
          }
        }
      }
    }
  }
}


@include desktop {
  .location-component {
    border: 1px solid $mystic;
    padding: 20px;
    margin: 24.15px auto;
    .location-header {
      font-size: 20px;
      color: $waikawa-gray;
    }
    .location-info {
      color: $shuttle-gray;
      font-size: 13px;
      .location-title {
        color: $black-gray-text;
        font-family: $font-family-semi-bold;
        font-size: 14px;
      }
      
    }
    .location-point-map {
      margin-top: 39px;
      width: 100%;
      height: 234px;
    }
  }
}
