.wrapper-container {
  margin-left: $menu-desktop-width-expanded;
  padding-top: 50px;
  transition: all 0.5s ease-in-out;
  background-color: var(--platform-bg-color);

  @include mobile-only {
    margin: -56px 0 0;
    padding: 0;
  }
}

@include tablet-only {
  .wrapper-container {
    margin-left: 84px;
    transition: all 0.5s ease-in-out;
  }
}

.collapsed {
  .wrapper-container {
    margin-left: 62px;
    transition: all 0.5s ease-in-out;
  }
}

@include mobile-only {
  .collapsed .wrapper-container {
    margin: -56px -8px 50px -8px;
    transition: all 0.5s ease-in-out;
  }
}
