.appointments {
  &__container {
    position: relative;
    padding: 21px 12px;
    background: $white;
  }

  &__item {
    position: relative;
    min-height: 142px;

    &:first-child {
      .appointments__calendar-line {
        top: 20px;
      }
    }

    &:last-child {
      .appointments__calendar-line {
        top: 20px;
        width: 3%;
        border: none;
        background-image: url(../images/dashed-line.svg);
      }
    }
  }

  &__calendar-container {
    position: absolute;
    width: $appointments-calendar-container-mobile-width;
    height: 100%;
  }

  &__calendar-item {
    position: absolute;
    top: 0;
    left: 0;
    width: $appointments-calender-item-width;
    height: $appointments-calender-item-height;
    font-size: 12px;
    font-family: $base-font-family;

    &::before {
      position: absolute;
      left: 50%;
      width: 52px;
      height: 58px;
      background-size: cover;
      content: "";
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  &__status {
    position: relative;
    margin-top: 12px;
    color: $green-granted;
    font-family: $font-family-semi-bold;
    line-height: 15px;
  }

  &__icon-label {
    &--completed {
      margin-left: 17px;
      color: $blue-completed;

      &::before {
        position: absolute;
        top: 1px;
        left: -2px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $blue-completed none repeat scroll 0 0;
        content: " ";
      }

      &::after {
        position: absolute;
        top: 2px;
        left: 0;
        color: $white;
        content: "x";
        vertical-align: middle;
        font-size: 9px;
        font-family: $font-family-icon-label-icons;
      }
    }

    &--confirmed {
      &::before {
        margin-right: 5px;
        content: "x";
        vertical-align: middle;
        font-size: 12px;
        font-family: $font-family-icon-label-icons;
      }
    }

    &--completed,
    &--confirmed {
      font-size: 12px;
    }
  }

  &__calendar-item-text {
    position: relative;
    display: block;
    margin-top: -48px;
    width: $appointments-calender-item-width;
    color: $blue-dark-menu;
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family-semi-bold;
    line-height: 15px;
  }

  &__calendar-line {
    position: absolute;
    left: 28px;
    height: 125%;
    border-width: 1px;
    border-style: solid;
    border-color: $blue-stroke;
  }

  &__content-container {
    margin-left: $appointments-calendar-container-mobile-width;
  }

  &__text {
    display: block;
    font-size: 14px;
  }

  &__text--time {
    color: $blue-dark-menu;
    font-size: 16px;
    font-family: $font-family-bold;
  }

  &__text-item {
    font-family: $base-font-family;
    font-color: $shuttle-gray;
  }

  &__text-item-detail {
    font-family: $font-family-semi-bold;
  }
}

@include mobile-only {
  .appointments {
    &__calendar-item {
      right: 0;
      margin: auto;
    }

    &__calendar-item-text {
      position: absolute;
      top: 25px;
      right: 0;
      left: 0;
      margin: auto;
    }

    &__calendar-line {
      right: 0;
      left: 0;
      margin: auto;
      width: 1px;
    }

    &__button {
      padding-right: 16px;
      padding-left: 16px;
      width: auto;
      font-size: 13px;
    }
  }
}

@include tablet {
  .appointments {
    &__container {
      padding: 31px 28px;
    }

    &__calendar-container {
      position: absolute;
      width: $appointments-calendar-container-tablet-width;
    }

    &__content-container {
      margin-left: $appointments-calendar-container-tablet-width;
    }

    &__calendar-item {
      &::before {
        @include xy-align(absolute);
      }
    }
  }
}

@include desktop {
  .appointments {
    &__text {
      font-size: 12px;
    }

    &__text--time {
      font-size: 16px;
    }

    &__button {
      padding-right: 16px;
      padding-left: 16px;
      width: auto;
      height: 24px;
      font-size: 12px;
    }
  }
}

// remmed out, default calendar-icon not being used
// @include calendar-icon ( null, $calendar-icon__base, $calendar-icon__fill, $calendar-icon__stroke );
//@include calendar-icons();

@mixin calendar-icons() {
  @each $class, $base, $fill, $stroke in $calendar-icons {
    @include calendar-icon($class, $base, $fill, $stroke);
  }
}

@mixin calendar-icon($class, $base, $fill, $stroke) {
  @if $class != null {
    $class: '.' + $class;
  }

  .calendar-icon#{$class} {
    .calendar-icon-cls-1 {
      fill: none;
      clip-rule: evenodd;
    }
    //.calendar-icon-cls-2{clip-path:url(#clip-path);}
    .calendar-icon-cls-3 {
      fill: $fill;
      stroke: $stroke;
      stroke-width: 25.11px;
    }

    .calendar-icon-cls-3,
    .calendar-icon-cls-4 {
      fill-rule: evenodd;
    }

    .calendar-icon-cls-4 {
      fill: $base;
    }

    .calendar-icon-cls-5 {
      fill: $stroke;
    }
  }
}
