// Buttons //
@mixin button($modifier, $color, $background-color, $border-color: false, $background-hover: false, $background-active: false) {
  @if type-of($background-color) == 'color' {
    @if not $border-color {
      $border-color: $background-color;
    }

    @if not $background-hover {
      $background-hover: lighten($background-color, 5%);
    }

    @if not $background-active {
      $background-active: darken ($background-color, 5%);
    }
  }

  .button--#{$modifier} {
    display: inline-flex;
    margin: 0;
    padding: 12px 16px;
    width: auto;
    height: $button-height;
    border: 1px solid $border-color;
    border-radius: 5px;
    color: $color;
    white-space: nowrap;
    font-size: $button-font-size;
    font-family: $font-family-semi-bold;

    @include get-background-color($background-color, var(--platform-button-shadow));
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      color: $color;
      cursor: pointer;

      @include get-background-color($background-hover, var(--platform-button-shadow));
    }

    &:active {
      color: $color;

      @include get-background-color($background-active, null);
    }
  }
}

// Buttons-Icon //
@mixin icon-button($property, $icon-content, $label-desktop, $label-tablet, $label-mobile) {
  &.#{$property} {

    // set icon content
    &::before {
      display: flex;
      margin-right: 10px;
      content: $icon-content;
      font-size: $button-icon-font-size-mobile;
      font-family: $font-family-icon-label-icons;

      @include tablet {
        font-size: $button-icon-font-size-tablet;
      }

      @include desktop {
        font-size: $button-icon-font-size-desktop;
      }
    }
  }
}

// Icons //
@mixin icon-label-pseudo-state-colors() {
  @each $state, $class, $link, $active, $hover, $visited in $icon-label-colors {
    @include icon-label-pseudo-state-element($state, $class, $link, $active, $hover, $visited);
  }
}

@mixin icon-label-pseudo-state-element($state, $class, $link, $active, $hover, $visited) {
  @if $state != null {
    &.#{$state} {
      @include icon-label-pseudo-class(link, $link, $class);
      @include icon-label-pseudo-class(visited, $visited, $class);
      @include icon-label-pseudo-class(hover, $hover, $class);
      @include icon-label-pseudo-class(active, $active, $class);
    }
  }

  @else {
    & {
      @include icon-label-pseudo-class(link, $link, $class);
      @include icon-label-pseudo-class(visited, $visited, $class);
      @include icon-label-pseudo-class(hover, $hover, $class);
      @include icon-label-pseudo-class(active, $active, $class);
    }
  }
}

@mixin icon-label-pseudo-class($pseudo, $color, $class) {
  &:#{$pseudo} {
    .#{$class} {
      color: $color;
    }
  }
}

@mixin icon-label($property, $icon-content, $label-desktop, $label-tablet, $label-mobile) {
  &.#{$property} {

    // set icon content
    .icon-label__icon::before {
      content: $icon-content;
    }

    // set label content
    .icon-label__label::before {
      content: $label-mobile;

      @include tablet {
        content: $label-tablet;
      }

      @include desktop {
        content: $label-desktop;
      }
    }
  }
}

