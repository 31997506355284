ul.dropdown-options {
  padding: 15px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: $button-alternate-box-shadow;
  font-family: proximanova;
  font-size: 16px;
  color: $dropdown-text-color;
  display: inline-block;

  li {

    &.selected {

      &:before {
        content: "x";
        font-family: "patient-portal";
        color: $success-color;
        margin-right: 4px;
      }
    }

    &.not-selected {
      margin-left: 20px;
    }
  }
}
